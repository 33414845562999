import React, { useState, useEffect } from 'react';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import Button from 'components/interface/button';
import Checkbox from 'components/interface/checkbox';
import ThreadsSearch from '../threadsSearch';
import ThreadUnreadFilter from '../threadsUnreadFilter';
import ThreadsFilterButton from '../threadsFilterButton';
import ThreadsNewMessageButton from '../threadsNewMessageButton';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsHeader
 * @description Inbox threads header component
 * @returns {TSX.Element} InboxThreadsHeader component
 */

const InboxThreadsHeader: React.FC = () => {
  const {
    inbox,
    inboxFilters,
    setSelectedThreadIds,
    exportThreadTranscriptFile,
  } = useInbox();

  const [isCheckAllSelected, setIsCheckAllSelected] = useState(false);

  const toggleAllSelected = (isCheckAllSelected: boolean) => {
    if (isCheckAllSelected) {
      setSelectedThreadIds(
        Object.values(inbox.threads).map((thread) => thread.id),
      );
    } else {
      setSelectedThreadIds([]);
    }
  };

  useEffect(() => {
    if (inbox.selectedThreadsIds.length > 0) {
      setIsCheckAllSelected(true);
    } else {
      setIsCheckAllSelected(false);
    }
  }, [inbox.selectedThreadsIds]);

  return (
    <div className={'inbox-threads-header'}>
      <div className={'inbox-threads-header-row'}>
        <div>
          <Text variant={'b3'} color={'var(--gray)'}>
            {inboxFilters.showAssignedOnly == false
              ? 'Inbox Principal'
              : 'Inbox Asignados'}
          </Text>
          <Text variant={'h7'}>Conversaciones</Text>
        </div>
        <div style={{ display: 'flex', gap: 4 }}>
          <ThreadsFilterButton />
          <ThreadsNewMessageButton />
        </div>
      </div>
      <ThreadsSearch />
      <ThreadUnreadFilter />
      <div className={'inbox-threads-header-actions'}>
        <div className={'inbox-threads-header-checkbox'}>
          <Checkbox
            isChecked={isCheckAllSelected}
            onChange={() => {
              setIsCheckAllSelected(!isCheckAllSelected);
              toggleAllSelected(!isCheckAllSelected);
            }}
          />
          <div className={'inbox-threads-header-checkbox-icon'}>
            <Icon name={'caretDown'} color={'var(--gray-2)'} />
          </div>
        </div>
        <div className={'inbox-threads-header-actions-buttons'}>
          <Button
            variant={'secondary'}
            size={'small'}
            label={'Exportar Transcripción'}
            isDisabled={
              inbox.selectedThreadsIds.length <= 0 || inbox.isExportingThreads
            }
            isLoading={inbox.isExportingThreads}
            onClick={() => {
              exportThreadTranscriptFile(inbox.selectedThreadsIds.map(String));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InboxThreadsHeader;
