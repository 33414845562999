import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Input from 'components/interface/input';
import Button from 'components/interface/button';
import TextArea from 'components/interface/textArea';
import SettingsFields from 'components/interface/settingsFields';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import { type SettingFields } from 'components/interface/settingsFields';
import { type ChatbotData } from 'features/chatbots/providers/ChatbotProvider';

type FormInputs = {
  name: string;
  personality: string;
  instructionsWhatsapp: string;
  instructionsPhoneCall: string;
  inboundGreeting: string;
  objective: string;
  businessName: string;
  businessDescription: string;
};

type GeneralFormProps = {
  chatbotData: ChatbotData;
};

const GeneralForm: React.FC<GeneralFormProps> = ({ chatbotData }) => {
  const { userHasPermission } = useUser();
  const { updateChatbotBehaviorData } = useChatbot();
  const [hasWhatsappIntegration, setHasWhatsappIntegration] =
    useState<boolean>(false);
  const [hasPhoneCallIntegration, setHasPhoneCallIntegration] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setHasWhatsappIntegration(
      Object.keys(chatbotData.whatsappIntegrations).length > 0,
    );
    setHasPhoneCallIntegration(
      Object.keys(chatbotData.phoneCallIntegrations).length > 0,
    );
  }, [chatbotData]);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      name: chatbotData.name,
      personality: chatbotData.personality,
      instructionsWhatsapp: chatbotData.instructionsWhatsapp,
      instructionsPhoneCall: chatbotData.instructionsPhoneCall,
      inboundGreeting: chatbotData.inboundGreeting,
      objective: chatbotData.objective,
      businessName: chatbotData.businessName,
      businessDescription: chatbotData.businessDescription,
    },
  });

  useEffect(() => {
    setValue('name', chatbotData.name);
    setValue('personality', chatbotData.personality);
    setValue('instructionsWhatsapp', chatbotData.instructionsWhatsapp);
    setValue('instructionsPhoneCall', chatbotData.instructionsPhoneCall);
    setValue('inboundGreeting', chatbotData.inboundGreeting);
    setValue('objective', chatbotData.objective);
    setValue('businessName', chatbotData.businessName);
    setValue('businessDescription', chatbotData.businessDescription);
  }, [chatbotData, setValue]);

  const isAccessible =
    userHasPermission('bots.all.update') ||
    userHasPermission(`bots.${chatbotData.id}.update`);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (isLoading) return;
    setIsLoading(true);

    const response = await updateChatbotBehaviorData(
      chatbotData.id,
      data.name,
      data.personality,
      data.instructionsWhatsapp ?? '',
      data.instructionsPhoneCall ?? '',
      data.inboundGreeting ?? '',
      data.objective,
      data.businessName,
      data.businessDescription,
    );

    if (response?.errors) {
      if (response.errors?.name) {
        setError('name', {
          type: 'manual',
          message: response.errors?.name,
        });
      }
      if (response.errors?.personality) {
        setError('personality', {
          type: 'manual',
          message: response.errors?.personality,
        });
      }
      if (response.errors?.instructionsWhatsapp) {
        setError('instructionsWhatsapp', {
          type: 'manual',
          message: response.errors?.instructionsWhatsapp,
        });
      }
      if (response.errors?.instructionsPhoneCall) {
        setError('instructionsPhoneCall', {
          type: 'manual',
          message: response.errors?.instructionsPhoneCall,
        });
      }
      if (response.errors?.inboundGreeting) {
        setError('inboundGreeting', {
          type: 'manual',
          message: response.errors?.inboundGreeting,
        });
      }
      if (response.errors?.objective) {
        setError('objective', {
          type: 'manual',
          message: response.errors?.objective,
        });
      }
      if (response.errors?.businessName) {
        setError('businessName', {
          type: 'manual',
          message: response.errors?.businessName,
        });
      }
      if (response.errors?.businessDescription) {
        setError('businessDescription', {
          type: 'manual',
          message: response.errors?.businessDescription,
        });
      }
      if (response.errors?.non_field_errors) {
        response.errors?.non_field_errors.map((error: string) => {
          toast.error(error);
        });
        reset();
      }
      if (typeof response.errors === 'string') {
        toast.error('Error al actualizar los parametros');
      }
    } else {
      if (response?.data?.success) {
        toast.success('Parametros actualizados correctamente');
      } else {
        toast.error('Error al actualizar los parametros');
        reset();
      }
    }
    setIsLoading(false);
  };

  const fields: SettingFields = {
    name: {
      label: 'Nombre del asistente',
      description:
        'El nombre del asistente es el nombre visible para el usuario y parte de su personalidad.',
      component: (
        <Input
          placeholder={'Nombre del asistente'}
          isDisabled={!isAccessible}
          error={errors?.name?.message}
          {...register('name')}
        />
      ),
    },
    personality: {
      label: 'Personalidad del asistente',
      description:
        'La personalidad del asistente define como se comportará el asistente al momento de interactuar con los usuarios.',
      component: (
        <TextArea
          placeholder={'Descripción de personalidad'}
          isDisabled={!isAccessible}
          error={errors?.personality?.message}
          {...register('personality')}
        />
      ),
    },
    instructionsWhatsapp: {
      label: 'Instrucciones para WhatsApp',
      description:
        'Aquí se pueden definir instrucciones sobre asuntos específicos como por ejemplo: “Al inicio de una conversación dí: Hola, estás hablando con el asistente de Altur”.',
      component: (
        <TextArea
          placeholder={
            hasWhatsappIntegration
              ? 'Instrucciones para WhatsApp'
              : 'Habilita la integración de WhatsApp para configurar instrucciones'
          }
          isDisabled={!isAccessible || !hasWhatsappIntegration}
          error={errors?.instructionsWhatsapp?.message}
          {...register('instructionsWhatsapp')}
        />
      ),
    },
    instructionsPhoneCall: {
      label: 'Instrucciones para Llamadas',
      description:
        'Aquí se pueden definir instrucciones sobre asuntos específicos como por ejemplo: “Al inicio de una llamada dí: Hola, estás hablando con el asistente de Altur”.',
      component: (
        <TextArea
          placeholder={
            hasPhoneCallIntegration
              ? 'Instrucciones para Llamadas'
              : 'Habilita la integración de llamadas para configurar instrucciones'
          }
          isDisabled={!isAccessible || !hasPhoneCallIntegration}
          error={errors?.instructionsPhoneCall?.message}
          {...register('instructionsPhoneCall')}
        />
      ),
    },
    inboundGreeting: {
      label: 'Saludo para llamada entrante',
      description:
        'El saludo para llamada entrante es el mensaje que el asistente dirá al momento de recibir una llamada entrante.',
      component: (
        <Input
          placeholder={'¿Bueno? ¿Con quién tengo el gusto?'}
          isDisabled={!isAccessible || !hasPhoneCallIntegration}
          error={errors?.inboundGreeting?.message}
          {...register('inboundGreeting')}
        />
      ),
    },
    objective: {
      label: 'Objetivo',
      description:
        'El objetivo del asistente es una descripción corta pero clara de lo que el asistente puede hacer por los usuarios o el objetivo que tiene la interacción con un usuario.',
      component: (
        <TextArea
          placeholder={'Objetivo'}
          isDisabled={!isAccessible}
          error={errors?.objective?.message}
          {...register('objective')}
        />
      ),
    },
    businessName: {
      label: 'Nombre del negocio',
      description:
        'El nombre del negocio es el nombre de la empresa o negocio que representa el asistente.',
      component: (
        <Input
          placeholder={'Nombre del negocio'}
          isDisabled={!isAccessible}
          error={errors?.businessName?.message}
          {...register('businessName')}
        />
      ),
    },
    businessDescription: {
      label: 'Descripción del negocio',
      description:
        'La descripción del negocio es una descripción corta del negocio u organización que representa el asistente.',
      component: (
        <TextArea
          placeholder={'Descripción del negocio'}
          isDisabled={!isAccessible}
          error={errors?.businessDescription?.message}
          {...register('businessDescription')}
        />
      ),
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SettingsFields fields={fields} />
      {isAccessible ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
          <Button
            label={'Cancelar'}
            variant={'secondary'}
            onClick={() => reset()}
          />
          <Button
            type={'submit'}
            label={'Guardar'}
            variant={'primary'}
            isLoading={isLoading}
          />
        </div>
      ) : null}
    </form>
  );
};

export default GeneralForm;
