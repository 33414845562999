import React, { FC, createContext, useReducer, useMemo } from 'react';

import { UUID } from 'crypto';

import campaignReducer from './campaignReducer';

import { ExtractableDataFieldType } from '../../chatbots/enums';

export const CAMPAIGN_INTEGRATION_TYPE_PHONECALL =
  'bots | Integration Phone Call';
export const CAMPAIGN_INTEGRATION_TYPE_WHATSAPP = 'bots | Integration Whatsapp';

export type CampaignSchedulingDays =
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat'
  | 'sun';

export type CampaignSchedulingDay = {
  active: boolean;
  startTime: string;
  stopTime: string;
};

export type CampaignScheduling = {
  [key in CampaignSchedulingDays]: CampaignSchedulingDay;
};
// todo: Use this type in app, right now they are not used for development speed
export type CampaignStatsPhonecall = {
  detonationsSent: number;
  detonationsDelivered: number;
  detonationsDeliveredRate: number;
  detonationsDeliveredMachine: number;
  detonationsDeliveredMachineRate: number;
  detonationsDeliveredHuman: number;
  detonationsDeliveredHumanRate: number;
  contacts: number;
  contactsReached: number;
  contactsReachedRate: number;
  contactsVoicemail: number;
  contactsVoicemailRate: number;
  contactsReplied: number;
  contactsRepliedRate: number;
  contactsConverted: number;
  contactsConvertedRate: number;
};
// todo: This one too :)
export type CampaignStatsWhatsapp = {
  contacts: number;
  sent: number;
  delivered: number;
  voicemail: number;
  read: number;
  converted: number;
  replied: number;
  failed: number;
  rejected: number;
  completed: number;
  completionRate: number;
  sentRate: number;
  failedRate: number;
  deliveryRate: number;
  voicemailRate: number;
  readRate: number;
  rejectionRate: number;
  repliedRate: number;
  convertedRate: number;
};

export type CampaignStats = {
  [key: string]: any;
};

export type CampaignContact = {
  campaignId: number;
  fId: string;
  contact: string;
  name: string;
  context: string;
  status: string;
  retries: number;
  lastUpdate: string;
  extractedData: {
    [key: string]: {
      name: string;
      type: ExtractableDataFieldType;
      value: string;
    };
  };
};

export type CampaignCall = {
  id: string;
  numberTo: string;
  type: string;
  status: string;
  answeredBy: string;
  createdAt: string;
  startedAt: string;
  endedAt: string;
  duration: number;
  billedDuration: number;
  recordingUrl: string;
};

export type CampaignData = {
  id: string;
  name: string;
  description: string;
  botId: UUID;
  integrationId: string;
  integrationType: string;
  status: string;
  scheduling: CampaignScheduling;
  tz: string;
  createdAt: string;
  retries: number;
  template: string;
  templateAlt: string;
  templateImage: string;
  firstMessage: string;
  messageLimit: number;
  archived: boolean;
  campaignStats: CampaignStats;
  campaignContacts: CampaignContact[];
  campaignContactsCount: number;
  campaignCalls: CampaignCall[];
  campaignCallsCount: number;
  campaignResults: CampaignContact[];
  campaignResultsCount: number;
};

export type CampaignsData = {
  [key: string]: CampaignData;
};

export type CampaignsState = {
  campaignsData: CampaignsData;
  saveCampaignsData: (
    update:
      | CampaignsData
      | ((prevCampaignsData: CampaignsData) => CampaignsData),
  ) => void;
};

type CampaignProviderProps = {
  children: React.ReactNode;
};

export const CampaignContext = createContext<CampaignsState>({
  campaignsData: {},
  saveCampaignsData: () => {},
});

const CampaignProvider: FC<CampaignProviderProps> = ({ children }) => {
  const initialState: CampaignsState = {
    campaignsData: {},
    saveCampaignsData: (
      update:
        | CampaignsData
        | ((prevCampaignsData: CampaignsData) => CampaignsData),
    ) => {
      return;
    },
  };

  const [state, dispatch] = useReducer(campaignReducer, initialState);

  const saveCampaignsData = (
    update:
      | CampaignsData
      | ((prevCampaignsData: CampaignsData) => CampaignsData),
  ) => {
    dispatch({ type: 'SAVE_CAMPAIGNS_DATA', payload: update });
  };

  const contextValue = useMemo(() => {
    return {
      campaignsData: state.campaignsData,
      saveCampaignsData,
    };
  }, [state.campaignsData]);

  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignProvider;
