import React, { FC, createContext, useReducer, useMemo } from 'react';

import jobReducer from './jobReducer';

import { JobTypeEnum, JobStatusEnum } from '../enums';

export type JobData = {
  id: string;
  name: string;
  type: JobTypeEnum;
  status: JobStatusEnum;
  progress: number;
  progressMessage: string;
  firstSeen: string;
  isHidden: boolean;
};

export type JobsData = {
  [key: string]: JobData;
};

export type JobState = {
  jobs: JobsData;
  saveJobs: (jobs: JobsData) => void;
};

export type JobProviderProps = {
  children: React.ReactNode;
};

export const JobContext = createContext<JobState>({
  jobs: {},
  saveJobs: () => {},
});

export const JobProvider: FC<JobProviderProps> = ({ children }) => {
  const initialState: JobState = {
    jobs: {},
    saveJobs: (jobs: JobsData) => {
      return null;
    },
  };

  const [state, dispatch] = useReducer(jobReducer, initialState);

  const saveJobs = (jobs: JobsData) => {
    dispatch({
      type: 'SAVE_JOBS',
      payload: jobs,
    });
  };

  const contextValue = useMemo(() => {
    return {
      jobs: state.jobs,
      saveJobs,
    };
  }, [state.jobs]);

  return (
    <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>
  );
};

export default JobProvider;
