import React, { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useUser } from 'features/user/hooks/useUser';
import { useJob } from 'features/jobs/hooks/useJob';
import { useOrganization } from 'features/organization/hooks/useOrganization';
import { useCampaign } from 'features/campaigns/hooks/useCampaign';
import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { useNotification } from 'features/notifications/hooks/useNotification';

export const ClientSocket = () => {
  const { userDisplayData } = useUser();
  const { receiveJobTrackerUpdate } = useJob();
  const { updateOrganizationMemberStatus } = useOrganization();
  const { downloadCampaignContactsFile } = useCampaign();
  const { updateClientSocketStatus } = useDashboard();
  const { appendNotification } = useNotification();

  const websocketUrl = `${process.env.REACT_APP_WEST_CLIENT_WS_URL}/${userDisplayData.id}/`;
  const { lastJsonMessage, readyState } = useWebSocket(websocketUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000,
    share: true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      updateClientSocketStatus(true, false);
    } else if (readyState === ReadyState.CONNECTING) {
      updateClientSocketStatus(false, true);
    } else {
      updateClientSocketStatus(false, false);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastJsonMessage?.type === 'organization_user_status_update') {
      updateOrganizationMemberStatus(
        lastJsonMessage?.userId,
        lastJsonMessage?.isOnline,
        lastJsonMessage?.lastOnline,
      );
    }
    if (lastJsonMessage?.type === 'notification') {
      const auxNotification = lastJsonMessage;
      auxNotification.type = lastJsonMessage?.notificationType;
      appendNotification(auxNotification);
    }
    if (lastJsonMessage?.type === 'job_tracker') {
      receiveJobTrackerUpdate(lastJsonMessage?.job);
    }
    if (lastJsonMessage?.type === 'download_campaign') {
      downloadCampaignContactsFile(lastJsonMessage?.filePath);
    }
  }, [lastJsonMessage]);

  return <></>;
};

export default ClientSocket;
