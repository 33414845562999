import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import WaveSurfer from 'wavesurfer.js';

import Icon from 'components/interface/icon';
import Text from 'components/interface/text';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

type AudioPlayerProps = {
  url?: string;
  isCallRecording?: boolean;
  allowDownload?: boolean;
  isPlayingExternal?: boolean;
  isPlayingCallback?: (boolean: boolean) => void;
};

/**
 * AudioPlayer
 * @description Audio player component
 * @param {string} url - Audio url
 * @returns {TSX.Element} Audio player component
 */

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  url,
  isCallRecording = true,
  allowDownload = true,
  isPlayingExternal = false,
  isPlayingCallback = (boolean: boolean) => {},
}) => {
  const audioPlayerRef = useRef<HTMLDivElement>(null);
  const isFetchingRef = useRef<boolean>(false);
  const hasLoadedOnceRef = useRef<boolean>(false);
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { getCallRecording, getS3File } = useInbox();

  useEffect(() => {
    setIsPlaying(isPlayingExternal);
  }, [isPlayingExternal]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 },
    );

    if (audioPlayerRef.current) {
      observer.observe(audioPlayerRef.current);
    }

    return () => {
      if (audioPlayerRef.current) {
        observer.unobserve(audioPlayerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let wavesurfer: WaveSurfer | null = null;
    let isMounted = true;
    const abortController = new AbortController();

    const fetchAudio = async () => {
      if (!url || !isVisible || isFetchingRef.current) return;

      if (hasLoadedOnceRef.current && wavesurferRef.current) {
        if (waveformRef.current) {
          waveformRef.current.style.display = 'block';
        }
        setIsWaveSurferReady(true);
        return;
      }

      isFetchingRef.current = true;

      try {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
          wavesurferRef.current = null;
        }

        if (waveformRef.current) {
          waveformRef.current.innerHTML = '';
        }

        let blob: Blob | null = null;

        if (isCallRecording) {
          blob = await getCallRecording(url);
        } else {
          blob = await getS3File(url);
        }

        if (!isMounted || !isVisible) return;

        if (!(blob instanceof Blob)) {
          throw new Error('Invalid response: Expected Blob');
        }

        const audioUrl = window.URL.createObjectURL(blob);

        if (waveformRef.current && isMounted && isVisible) {
          wavesurfer = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: '#d4d4d4',
            progressColor: '#767676',
            cursorColor: '#f5f5f5',
            height: 32,
            barWidth: 3,
            barGap: 1,
            barRadius: 3,
            fillParent: true,
            normalize: true,
            dragToSeek: true,
          });

          wavesurfer.on('loading', (percentage: number) => {
            if (isMounted) setLoadingPercentage(percentage);
          });

          wavesurfer.on('ready', () => {
            if (isMounted) {
              setIsWaveSurferReady(true);
              hasLoadedOnceRef.current = true;
            }
          });

          wavesurfer.on('finish', () => {
            if (isMounted) {
              setIsPlaying(false);
              isPlayingCallback(false);
            }
          });

          wavesurfer.load(audioUrl);
          wavesurferRef.current = wavesurfer;
        }
      } catch (error) {
        console.error('Error fetching audio:', error);
        if (isMounted) setError('Error al cargar el audio');
      } finally {
        isFetchingRef.current = false;
      }
    };

    fetchAudio();

    return () => {
      isMounted = false;
      abortController.abort();

      if (!hasLoadedOnceRef.current && wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }

      isFetchingRef.current = false;
    };
  }, [url, isVisible]);

  const toggleAudio = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
      isPlayingCallback(!isPlaying);
    }
  };

  const downloadAudio = async () => {
    if (!url) {
      toast.error('El audio no está disponible');
      return;
    }

    const blob = await getCallRecording(url);
    const audioUrl = window.URL.createObjectURL(blob);

    fetch(audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        if (isCallRecording) {
          a.download = `${url}.mp3`;
        } else {
          a.download = 'audio.mp3';
        }

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      })
      .catch(() => {
        toast.error('Error al descargar el audio');
      });
  };

  return (
    <div ref={audioPlayerRef} className={'audio-player'}>
      <div style={{ cursor: 'pointer' }} onClick={toggleAudio}>
        <Icon
          name={isPlaying ? 'pause' : 'play'}
          style={'fill'}
          size={20}
          color={'var(--gray)'}
        />
      </div>
      {error ? (
        <div className={'error'}>
          <Text variant={'b3'} color={'var(--gray-2)'}>
            {error}
          </Text>
        </div>
      ) : (
        <>
          <div
            id={'waveform'}
            ref={waveformRef}
            style={{
              flex: 1,
              display: isWaveSurferReady ? 'block' : 'none',
              overflow: 'hidden',
            }}
          />
          <div
            className={'loading'}
            style={{ display: isWaveSurferReady ? 'none' : 'flex' }}
          >
            <Text variant={'b3'} color={'var(--gray-2)'}>
              Cargando... {loadingPercentage}%
            </Text>
          </div>
        </>
      )}
      {allowDownload && (
        <div style={{ cursor: 'pointer' }} onClick={downloadAudio}>
          <Icon
            name={'download'}
            style={'fill'}
            size={20}
            color={'var(--gray)'}
          />
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
