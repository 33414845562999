import React, { useEffect, useState } from 'react';

import { JobData } from 'features/jobs/providers/JobProvider';
import { JobTypeEnum, JobStatusEnum } from 'features/jobs/enums';

import { useJob } from 'features/jobs/hooks/useJob';

import Text from 'components/interface/text';
import Icon from 'components/interface/icon';
import IconButton from 'components/interface/iconButton';
import ProgressBar from 'components/interface/progressBar';

import './styles.css';

type JobTrackerItemProps = {
  job: JobData;
};

/**
 * JobTrackerItem
 * @description Displays a single job nicely
 * @returns {TSX.Element} JobTrackerItem component
 */
const JobTrackerItem: React.FC<JobTrackerItemProps> = ({ job }) => {
  const { hideJob } = useJob();
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (job.status === JobStatusEnum.COMPLETED) {
      setTimeout(() => {
        setIsCompleted(true);
      }, 1500);
      setTimeout(() => {
        hideJob(job.id);
      }, 2000);
    }
  }, [job.status]);

  const setJobHidden = () => {
    setIsCompleted(true);
    setTimeout(() => {
      hideJob(job.id);
    }, 500);
  };

  return (
    <div className={`job-tracker-item ${isCompleted ? 'completed' : ''}`}>
      <div className={'job-tracker-item-header'}>
        <div className={'job-tracker-item-header-info'}>
          <div className={'job-tracker-item-header-icon'}>
            {job.status === JobStatusEnum.PENDING && (
              <Icon name={'clock'} size={20} />
            )}
            {job.status === JobStatusEnum.RUNNING && (
              <>
                {job.type === JobTypeEnum.DATA_IMPORT && (
                  <Icon name={'fileArrowUp'} size={20} />
                )}
                {job.type === JobTypeEnum.DATA_EXPORT && (
                  <Icon name={'fileArrowDown'} size={20} />
                )}
              </>
            )}
            {job.status === JobStatusEnum.COMPLETED && (
              <Icon name={'checkCircle'} size={20} color={'var(--success)'} />
            )}
            {job.status === JobStatusEnum.FAILED && (
              <Icon name={'fileX'} size={20} color={'var(--error)'} />
            )}
          </div>
          <div className={'job-tracker-item-header-title'}>
            <Text variant={'h8'}>{job.name}</Text>
            {job.status === JobStatusEnum.PENDING && (
              <Text variant={'b3'} color={'var(--gray-2)'}>
                En fila de espera
              </Text>
            )}
            {(job.status === JobStatusEnum.RUNNING ||
              job.status === JobStatusEnum.COMPLETED ||
              job.status === JobStatusEnum.FAILED) && (
              <Text variant={'b3'} color={'var(--gray-2)'}>
                {job.progressMessage}
              </Text>
            )}
          </div>
        </div>
        <div className={'job-tracker-item-header-close'}>
          <IconButton
            size={28}
            iconName={'x'}
            onClick={setJobHidden}
            iconColor={'var(--gray-2)'}
          />
        </div>
      </div>
      <div className={'job-tracker-item-content'}>
        <div className={'job-tracker-item-content-progress'}>
          <ProgressBar
            progress={job.progress}
            color={
              job.status === JobStatusEnum.PENDING
                ? 'var(--warning)'
                : job.status === JobStatusEnum.RUNNING
                ? 'var(--primary)'
                : job.status === JobStatusEnum.COMPLETED
                ? 'var(--primary)'
                : 'var(--error)'
            }
          />
          <Text variant={'b3'}>{job.progress}%</Text>
        </div>
      </div>
    </div>
  );
};

export default JobTrackerItem;
