import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Text from 'components/interface/text';
import Loader from 'components/interface/loader';
import Icon from 'components/interface/icon';

import { useAuth } from '../hooks/useAuth';

import { colors } from 'constants/colors';

const ConfirmView = () => {
  const { uidb64, token } = useParams();
  const [state, setState] = useState('LOADING');
  const { verificationTry, verificationResend } = useAuth();

  const resendVerification = async () => {
    const response = await verificationResend(uidb64 ?? '', token ?? '');
    if (response === false) {
      toast.error('Error al reenviar el código de verificación');
      setState('ERROR');
      return;
    }
    setState('RESENT');
  };

  const STATES = {
    LOADING: {
      icon: <Loader size={40} />,
      title: 'Verificando tu cuenta, espera un momento',
      subtitle: 'En un momento serás redirigido...',
    },
    SUCCESS: {
      icon: <Icon size={40} color={colors.success} name={'checkCircle'} />,
      title: 'Tu cuenta ha sido verificada exitosamente',
      subtitle: (
        <>
          Puedes iniciar sesión <Link to={'/auth/login'}>aquí</Link>.
        </>
      ),
    },
    ERROR: {
      icon: <Icon size={40} color={colors.error} name={'warningCircle'} />,
      title: 'whoops! Algo salió mal',
      subtitle: (
        <>
          Parece que el código ha caducado o es invalido. Solicita uno nuevo{' '}
          <a onClick={resendVerification}>aquí</a>.
        </>
      ),
    },
    RESENT: {
      icon: <Icon size={40} color={colors.primary} name={'envelopeSimple'} />,
      title: '¡Listo! Hemos reenviado el código de verificación',
      subtitle: 'Revisa tu correo para verificar tu cuenta.',
    },
  };

  useEffect(() => {
    const confirm = async () => {
      try {
        const confirmationStatus = await verificationTry(
          uidb64 ?? '',
          token ?? '',
        );
        if (!confirmationStatus) {
          setState('ERROR');
          return;
        }
        setState('SUCCESS');
      } catch (error) {
        setState('ERROR');
      }
    };
    setTimeout(() => {
      confirm();
    }, 800);
  }, []);

  return (
    <div className={'auth-confirm'}>
      <div className={'auth-confirm-container'}>
        <div className={'auth-confirm-loader'}>
          {STATES[state as keyof typeof STATES].icon}
        </div>
        <Text variant={'h7'} align={'center'}>
          {STATES[state as keyof typeof STATES].title}
        </Text>
        <Text variant={'s1'} align={'center'}>
          {STATES[state as keyof typeof STATES].subtitle}
        </Text>
      </div>
      <div className={'auth-confirm-disclaimer'}>
        <Text variant={'s2'} align={'center'}>
          © 2025 Altur, S.A.P.I. de C.V.
        </Text>
      </div>
    </div>
  );
};

export default ConfirmView;
