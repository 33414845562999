import React from 'react';

import Text from 'components/interface/text';
import IconButton from 'components/interface/iconButton';
import EndUserPicture from '../endUserPicture';

import { useInbox } from 'features/inbox/hooks/useInbox';

import { type Thread } from 'features/inbox/types/inboxTypes';

import './styles.css';

/**
 * ConversationHeader
 * @description Header component for the inbox conversation
 * @param {Thread} thread - thread object
 * @returns {TSX.Element} ConversationHeader component
 */

type ConversationHeaderProps = {
  thread: Thread;
};

const ConversationHeader: React.FC<ConversationHeaderProps> = ({ thread }) => {
  const {
    inboxPreferences,
    toggleDetailsExpanded,
    exportThreadTranscriptFile,
  } = useInbox();

  return (
    <div className={'inbox-conversation-header'}>
      <div className={'inbox-conversation-header-user'}>
        <EndUserPicture endUser={thread.endUser} size={36} />
        <Text variant={'b1'} numberOfLines={1}>
          {thread.endUser?.displayName || 'Anónimo'}
        </Text>
      </div>
      <div className={'inbox-conversation-header-actions'}>
        <IconButton
          iconName={'download'}
          iconColor={'var(--gray)'}
          hint={'Descargar transcripción'}
          onClick={() => exportThreadTranscriptFile([thread.id.toString()])}
        />
        <IconButton
          iconName={
            inboxPreferences.detailsExpanded
              ? 'arrowLineRight'
              : 'arrowLineLeft'
          }
          iconColor={'var(--gray)'}
          onClick={toggleDetailsExpanded}
        />
      </div>
    </div>
  );
};

export default ConversationHeader;
