import { type ChatbotsState } from './ChatbotProvider';

export type ChatbotsTypes = 'SAVE_CHATBOTS_DATA' | 'SAVE_VOICES_DATA';

type ChatbotAction = {
  type: ChatbotsTypes;
  payload?: any;
};

const chatbotReducer = (state: ChatbotsState, action: ChatbotAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_CHATBOTS_DATA':
      return {
        ...state,
        chatbotsData: payload,
      };
    case 'SAVE_VOICES_DATA':
      return {
        ...state,
        voicesData: payload,
      };
    default:
      return state;
  }
};

export default chatbotReducer;
