import React from 'react';
import { toast } from 'react-toastify';

import { CampaignContext } from '../providers/CampaignProvider';

import {
  fetchCampaignsData,
  fetchCampaignStats,
  fetchCampaignContacts,
  fetchCampaignCalls,
  fetchCampaignResults,
  postCampaign,
  putCampaign,
  putCampaignScheduling,
  putCampaignStatus,
  putCampaignArchive,
  postCampaignContactsFile,
  postCampaignContactsList,
  getCampaignContactsFile,
  getDownloadCampaignContactsFile,
  getCampaignCallsFile,
  getCampaignCallRecordingsFile,
  getCampaignResultsFile,
} from '../functions/campaignFunctions';

import {
  type CampaignData,
  type CampaignScheduling,
} from '../providers/CampaignProvider';

/**
 * @hook useCampaign
 * @description This hook is used to access campaigns context
 * @return {Object} The campaigns context
 */
export const useCampaign = () => {
  const { campaignsData, saveCampaignsData } =
    React.useContext(CampaignContext);

  /**
   * @function getCampaignsData
   * @description Fetches campaigns from the API and saves it
   * @param {string[]} campaignIds Campaign IDs
   * @returns {Object} { status, data: {campaignsData} } | { status, ...errors }
   */
  const getCampaignsData = async (campaignIds?: string[]): Promise<any> => {
    const dataResponse = await fetchCampaignsData(campaignIds);

    let statsResponse = null;
    if (campaignIds) {
      statsResponse = await fetchCampaignStats(campaignIds);
    }

    if (dataResponse.status === 200) {
      const campaignsDataAux = dataResponse.data;
      const campaignStatsAux = statsResponse?.data?.stats || {};

      saveCampaignsData((prevCampaignsData) => {
        const campaignsDict = { ...prevCampaignsData };

        campaignsDataAux.forEach((campaign: CampaignData) => {
          if (campaignIds && !campaignIds.includes(campaign.id)) {
            return;
          }

          campaignsDict[campaign.id] = {
            ...prevCampaignsData[campaign.id],
            id: campaign.id,
            name: campaign.name,
            description: campaign.description,
            botId: campaign.botId,
            integrationId: campaign.integrationId,
            integrationType: campaign.integrationType,
            status: campaign.status,
            scheduling: campaign.scheduling,
            tz: campaign.tz,
            createdAt: campaign.createdAt,
            retries: campaign.retries,
            template: campaign.template,
            templateAlt: campaign.templateAlt,
            templateImage: campaign.templateImage,
            firstMessage: campaign.firstMessage,
            messageLimit: campaign.messageLimit,
            archived: campaign.archived,
            campaignStats:
              campaignStatsAux[campaign.id] ??
              prevCampaignsData[campaign.id]?.campaignStats ??
              {},
            campaignContacts:
              prevCampaignsData[campaign.id]?.campaignContacts ?? [],
            campaignContactsCount:
              prevCampaignsData[campaign.id]?.campaignContactsCount ?? 0,
            campaignCalls: prevCampaignsData[campaign.id]?.campaignCalls ?? [],
            campaignCallsCount:
              prevCampaignsData[campaign.id]?.campaignCallsCount ?? 0,
            campaignResults:
              prevCampaignsData[campaign.id]?.campaignResults ?? [],
            campaignResultsCount:
              prevCampaignsData[campaign.id]?.campaignResultsCount ?? 0,
          };
        });

        return campaignsDict;
      });
    }
    return dataResponse;
  };

  /**
   * @function getCampaignContacts
   * @description Fetches campaign contacts from the API and saves it
   * @param {string} campaignId Campaign ID
   * @param {number} pageIndex Page number
   * @param {number} pageSize Page size
   * @param {string} sortBy Sort column name
   * @param {string} sortOrder Sort order by 'asc' or 'desc'
   * @param {string} searchTerm Search term
   * @returns {Object} { status, data: {campaignContacts} } | { status, ...errors }
   */
  const getCampaignContacts = async (
    campaignId: string,
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchTerm?: string,
  ): Promise<any> => {
    const response = await fetchCampaignContacts(
      campaignId,
      pageIndex,
      pageSize,
      sortBy,
      sortOrder,
      searchTerm,
    );
    if (response.status === 200) {
      const campaignContacts = response.data?.campaignUsers;
      const campaignContactsCount = response.data?.count;
      const campaignsDataAux = { ...campaignsData };

      campaignsDataAux[campaignId].campaignContacts = campaignContacts;
      campaignsDataAux[campaignId].campaignContactsCount =
        campaignContactsCount;

      saveCampaignsData(campaignsDataAux);
    }
    return response;
  };

  /**
   * @function getCampaignCalls
   * @description Fetches campaign calls from the API and saves it
   * @param {string} campaignId Campaign ID
   * @param {number} pageIndex Page number
   * @param {number} pageSize Page size
   * @param {string} sortBy Sort column name
   * @param {string} sortOrder Sort order by 'asc' or 'desc'
   * @param {string} searchTerm Search term
   * @returns {Object} { status, data: {campaignCalls} } | { status, ...errors }
   */
  const getCampaignCalls = async (
    campaignId: string,
    pageIndex: number,
    pageSize: number,
    sortBy: string,
    sortOrder: 'asc' | 'desc',
    searchTerm?: string,
  ): Promise<any> => {
    const response = await fetchCampaignCalls(
      campaignId,
      pageIndex,
      pageSize,
      sortBy,
      sortOrder,
      searchTerm,
    );
    if (response.status === 200) {
      const campaignCalls = response.data?.calls;
      const campaignCallsCount = response.data?.count;
      const campaignsDataAux = { ...campaignsData };

      campaignsDataAux[campaignId].campaignCalls = campaignCalls;
      campaignsDataAux[campaignId].campaignCallsCount = campaignCallsCount;

      saveCampaignsData(campaignsDataAux);
    }
    return response;
  };

  /**
   * @function getCampaignResults
   * @description Fetches campaign results from the API and saves it
   * @param {string} campaignId Campaign ID
   * @param {number} pageIndex Page number
   * @param {number} pageSize Page size
   * @param {string} searchTerm Search term
   * @returns {Object} { status, data: {campaignResults} } | { status, ...errors }
   */
  const getCampaignResults = async (
    campaignId: string,
    pageIndex: number,
    pageSize: number,
    searchTerm?: string,
  ): Promise<any> => {
    const response = await fetchCampaignResults(
      campaignId,
      pageIndex,
      pageSize,
      searchTerm,
    );
    if (response.status === 200) {
      const campaignResults = response.data?.campaignUsers;
      const campaignResultsCount = response.data?.count;
      const campaignsDataAux = { ...campaignsData };

      campaignsDataAux[campaignId].campaignResults = campaignResults ?? [];
      campaignsDataAux[campaignId].campaignResultsCount =
        campaignResultsCount ?? 0;

      saveCampaignsData(campaignsDataAux);
    }
    return response;
  };

  /**
   * @function createCampaign
   * @description Creates a new campaign
   * @param {FormData} formData Campaign data
   * @returns {Object} { status } | { status, ...errors }
   */
  const createCampaign = async (formData: FormData): Promise<any> => {
    const response = await postCampaign(formData);
    if (response.status === 200) {
      getCampaignsData();
    }
    return response;
  };

  /**
   * @function updateCampaign
   * @description Updates campaign
   * @param {string} campaignId Campaign ID
   * @param {FormData} formData Campaign data
   * @returns {Object} { status } | { status, ...errors }
   */
  const updateCampaign = async (
    campaignId: string,
    formData: FormData,
  ): Promise<any> => {
    formData.append('id', campaignId);
    const response = await putCampaign(formData);
    if (response.status === 200) {
      getCampaignsData([campaignId]);
    }
    return response;
  };

  /**
   * @function updateCampaignScheduling
   * @description Updates campaign scheduling
   * @param {string} campaignId Campaign ID
   * @param {CampaignScheduling} scheduling Campaign scheduling
   * @returns {Object} { status } | { status, ...errors }
   */
  const updateCampaignScheduling = async (
    campaignId: string,
    scheduling: CampaignScheduling,
  ): Promise<any> => {
    const response = await putCampaignScheduling(campaignId, scheduling);
    if (response.status === 200) {
      getCampaignsData([campaignId]);
    }
    return response;
  };

  /**
   * @function updateCampaignStatus
   * @description Updates campaign status
   * @param {string} campaignId Campaign ID
   * @param {string} status Campaign status
   * @returns {Object} { status } | { status, ...errors }
   */
  const updateCampaignStatus = async (
    campaignId: string,
    status: string,
  ): Promise<any> => {
    const response = await putCampaignStatus(campaignId, status);
    if (response.status === 200) {
      getCampaignsData([campaignId]);
    }
    return response;
  };

  /**
   * @function updateCampaignArchive
   * @description Updates campaign archive
   * @param {string[]} campaignIds Campaign IDs
   * @param {boolean} archived Campaign archive
   * @returns {Object} { status } | { status, ...errors }
   */
  const updateCampaignArchive = async (
    campaignIds: string[],
    archived: boolean,
  ): Promise<any> => {
    const response = await putCampaignArchive(campaignIds, archived);
    if (response.status === 200) {
      getCampaignsData(campaignIds);
    }
    return response;
  };

  /**
   * @function uploadCampaignContactsFile
   * @description Uploads campaign contacts file
   * @param {string} campaignId Campaign ID
   * @param {FormData} formData Campaign contacts file
   * @returns {Object} { status } | { status, ...errors }
   */
  const uploadCampaignContactsFile = async (
    campaignId: string,
    formData: FormData,
  ): Promise<any> => {
    formData.append('campaignId', campaignId);
    const response = await postCampaignContactsFile(formData);
    if (response.status === 200) {
      getCampaignsData([campaignId]);
    }
    return response;
  };

  /**
   * @function addCampaignContactsFromList
   * @description Adds campaign contacts from list
   * @param {string} campaignId Campaign ID
   * @param {string} endUserListId End user list ID
   * @returns {Object} { status } | { status, ...errors }
   */
  const addCampaignContactsFromList = async (
    campaignId: string,
    endUserListId: string,
  ): Promise<any> => {
    const response = await postCampaignContactsList(campaignId, endUserListId);
    if (response.status === 200) {
      getCampaignsData([campaignId]);
    }
    return response;
  };

  /**
   * @function exportCampaignContactsFile
   * @description Exports campaign contacts file to file in s3
   * @param {string[]} campaignIds Campaign IDs
   * @returns {Object} { blob response } | { status, ...errors }
   */
  const exportCampaignContactsFile = async (
    campaignIds: string[],
  ): Promise<any> => {
    const response = await getCampaignContactsFile(campaignIds);
    if (response.status !== 200) {
      toast.error('Error al procesar el archivo de contactos');
      return response;
    } else {
      toast.info('Procesando descarga de archivo de contactos...');
    }
  };

  /**
   * @function exportCampaignCallsFile
   * @description Exports campaign calls file to file in s3
   * @param {string[]} campaignIds Campaign IDs
   * @returns {Object} { blob response } | { status, ...errors }
   */
  const exportCampaignCallsFile = async (
    campaignIds: string[],
  ): Promise<any> => {
    const response = await getCampaignCallsFile(campaignIds);
    if (response.status !== 200) {
      toast.error('Error al procesar el archivo de llamadas');
      return response;
    } else {
      toast.info('Procesando descarga de archivo de llamadas...');
    }
  };

  /**
   * @function exportCampaignCallRecordingsFile
   * @description Exports campaign call recordings file to file in s3
   * @param {string[]} campaignIds Campaign IDs
   * @returns {Object} { blob response } | { status, ...errors }
   */
  const exportCampaignCallRecordingsFile = async (
    campaignIds: string[],
  ): Promise<any> => {
    const response = await getCampaignCallRecordingsFile(campaignIds);
    if (response.status !== 200) {
      toast.error('Error al procesar el archivo de grabaciones de llamadas');
      return response;
    } else {
      toast.info(
        'Procesando descarga de archivo de grabaciones de llamadas...',
      );
    }
  };

  /**
   * @function exportCampaignResultsFile
   * @description Exports campaign results file to file in s3
   * @param {string[]} campaignIds Campaign IDs
   * @returns {Object} { blob response } | { status, ...errors }
   */
  const exportCampaignResultsFile = async (
    campaignIds: string[],
  ): Promise<any> => {
    const response = await getCampaignResultsFile(campaignIds);
    if (response.status !== 200) {
      toast.error('Error al procesar el archivo de resultados');
      return response;
    } else {
      toast.info('Procesando descarga de archivo de resultados...');
    }
  };

  /**
   * @function downloadCampaignContactsFile
   * @description Downloads campaign contacts file from s3
   * @param {string} filePath File path returned by websocket
   * @returns {Object} { blob response } | { status, ...errors }
   */
  const downloadCampaignContactsFile = async (
    filePath: string,
  ): Promise<any> => {
    const response = await getDownloadCampaignContactsFile(filePath);

    if (response.status !== 200) {
      toast.error('Error al descargar el archivo de contactos');
      return response;
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const fileNameWithExt = `${filePath.split('/').pop()}`;
    const fileExt = fileNameWithExt.split('.').pop();
    const fileName = fileNameWithExt.split('.').slice(0, -1).join('.');
    const fileDownloadName = `${fileName}.${fileExt}`;

    link.setAttribute('download', fileDownloadName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  };

  return {
    campaignsData,
    getCampaignsData,
    getCampaignContacts,
    getCampaignCalls,
    getCampaignResults,
    createCampaign,
    updateCampaign,
    updateCampaignScheduling,
    updateCampaignStatus,
    updateCampaignArchive,
    uploadCampaignContactsFile,
    addCampaignContactsFromList,
    exportCampaignContactsFile,
    exportCampaignCallsFile,
    exportCampaignCallRecordingsFile,
    exportCampaignResultsFile,
    downloadCampaignContactsFile,
  };
};
