import React, { useEffect, useState } from 'react';

import { JobData } from 'features/jobs/providers/JobProvider';

import { useJob } from 'features/jobs/hooks/useJob';

import JobTrackerItem from 'features/jobs/components/jobTrackerItem';
import JobTrackerOverflow from 'features/jobs/components/jobTrackerOverflow';

import './styles.css';

/**
 * JobTrackerDisplayer
 * @description Displays all current jobs nicely
 * @returns {TSX.Element} JobTrackerDisplayer component
 */
const JobTrackerDisplayer: React.FC = () => {
  const { jobs } = useJob();

  const [jobsToDisplay, setJobsToDisplay] = useState<JobData[]>([]);
  const [jobOverflow, setJobOverflow] = useState(0);

  useEffect(() => {
    const jobsToDisplay = Object.values(jobs)
      .filter((job) => !job.isHidden)
      .sort(
        (a, b) =>
          new Date(b.firstSeen).getTime() - new Date(a.firstSeen).getTime(),
      );

    setJobsToDisplay(jobsToDisplay.slice(-3));
    setJobOverflow(jobsToDisplay.length - 3);
  }, [jobs]);

  return (
    <div className={'job-tracker-displayer-container'}>
      <div className={'job-tracker-displayer'}>
        {jobOverflow > 0 && <JobTrackerOverflow jobOverflow={jobOverflow} />}
        {jobsToDisplay.map((job) => (
          <JobTrackerItem key={job.id} job={job} />
        ))}
      </div>
    </div>
  );
};

export default JobTrackerDisplayer;
