import api from 'api';

import { type CampaignScheduling } from '../providers/CampaignProvider';

/**
 * fetchCampaignsData
 * @description Fetches campaigns from the API
 * @param {string[]} campaignIds Campaign IDs
 * @returns {Object} { status, data: {campaignsData} } | { status, ...errors }
 */

export const fetchCampaignsData = async (campaignIds?: string[]) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/campaigns/${
        campaignIds ? `?ids=${campaignIds.join(',')}` : ''
      }`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCampaignStats
 * @description Fetches campaign stats from the API
 * @returns {Object} { status, data: {campaignStats} } | { status, ...errors }
 */

export const fetchCampaignStats = async (campaignIds: string[]) => {
  try {
    const response = await api.get(
      `${
        process.env.REACT_APP_WEST_BASE_URL
      }/campaigns/stats/?ids=${campaignIds.join(',')}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postCampaign
 * @description Creates a new campaign
 * @param {FormData} formData Campaign data
 * @returns {Object} { status } | { status, ...errors }
 */

export const postCampaign = async (formData: FormData) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putCampaign
 * @description Updates campaign data
 * @param {FormData} formData Campaign data
 * @returns {Object} { status } | { status, ...errors }
 */

export const putCampaign = async (formData: FormData) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putCampaignScheduling
 * @description Updates campaign scheduling
 * @param {string} campaignId Campaign ID
 * @param {CampaignScheduling} scheduling Campaign scheduling
 * @returns {Object} { status } | { status, ...errors }
 */

export const putCampaignScheduling = async (
  campaignId: string,
  scheduling: CampaignScheduling,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/scheduling/',
      {
        campaignId,
        scheduling,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putCampaignStatus
 * @description Updates campaign status
 * @param {string} campaignId Campaign ID
 * @param {string} status Campaign status
 * @returns {Object} { status } | { status, ...errors }
 */

export const putCampaignStatus = async (campaignId: string, status: string) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/status/',
      {
        campaignId,
        status,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putCampaignArchive
 * @description Archives campaign
 * @param {string[]} campaignIds Campaign IDs
 * @param {boolean} archive Campaign archive status
 * @returns {Object} { status } | { status, ...errors }
 */

export const putCampaignArchive = async (
  campaignIds: string[],
  archived: boolean,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/archive/',
      {
        campaignIds,
        archived,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCampaignContacts
 * @description Fetches campaign contacts from the API
 * @param {string} campaignId Campaign ID
 * @param {number} pageIndex Page index
 * @param {number} pageSize Page size
 * @param {string} sortBy Sort by 'asc' or 'desc'
 * @param {string} sortOrder Sort order
 * @param {string} searchTerm Search term
 */

export const fetchCampaignContacts = async (
  campaignId: string,
  pageIndex: number,
  pageSize: number,
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  searchTerm?: string,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/campaigns/contacts/?id=${campaignId}&pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCampaignCalls
 * @description Fetches campaign calls from the API
 * @param {string} campaignId Campaign ID
 * @param {number} pageIndex Page index
 * @param {number} pageSize Page size
 * @param {string} sortBy Sort by 'asc' or 'desc'
 * @param {string} sortOrder Sort order
 * @param {string} searchTerm Search term
 */

export const fetchCampaignCalls = async (
  campaignId: string,
  pageIndex: number,
  pageSize: number,
  sortBy: string,
  sortOrder: 'asc' | 'desc',
  searchTerm?: string,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/campaigns/calls/?id=${campaignId}&pageIndex=${pageIndex}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchTerm=${searchTerm}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchCampaignResults
 * @description Fetches campaign results from the API
 * @param {string} campaignId Campaign ID
 * @param {number} pageIndex Page index
 * @param {number} pageSize Page size
 * @param {string} searchTerm Search term
 */

export const fetchCampaignResults = async (
  campaignId: string,
  pageIndex: number,
  pageSize: number,
  searchTerm?: string,
) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/campaigns/results/?id=${campaignId}&pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postCampaignContactsFile
 * @description Uploads a file with contacts to a campaign
 * @param {FormData} formData File data
 * @returns {Object} { status } | { status, ...errors }
 */

export const postCampaignContactsFile = async (formData: FormData) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/contacts/import/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data || error?.message,
    };
  }
};

/**
 * postCampaignContactsList
 * @description Adds contacts from an EndUserList to a campaign
 * @param {string} campaignId Campaign ID
 * @param {string} endUserListId EndUserList ID
 */

export const postCampaignContactsList = async (
  campaignId: string,
  endUserListId: string,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/campaigns/contacts/import/list/',
      {
        campaignId,
        endUserListId,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * getCampaignContactsFile
 * @description Requests to start job to build and upload contacts file to s3
 * @param {string} campaignIds Campaign IDs
 * @returns {Object} { status } | { status, ...errors }
 */

export const getCampaignContactsFile = async (campaignIds: string[]) => {
  try {
    const response = await api.get(
      `${
        process.env.REACT_APP_WEST_BASE_URL
      }/campaigns/contacts/export/?ids=${campaignIds.join(',')}`,
    );

    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.message,
    };
  }
};

/**
 * getCampaignCallsFile
 * @description Requests to start job to build and upload calls file to s3
 * @param {string} campaignIds Campaign IDs
 * @returns {Object} { status } | { status, ...errors }
 */

export const getCampaignCallsFile = async (campaignIds: string[]) => {
  try {
    const response = await api.get(
      `${
        process.env.REACT_APP_WEST_BASE_URL
      }/campaigns/calls/export/?ids=${campaignIds.join(',')}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.message,
    };
  }
};

/**
 * getCampaignCallRecordingsFile
 * @description Requests to start job to build and upload call recordings file to s3
 * @param {string} campaignIds Campaign IDs
 * @returns {Object} { status } | { status, ...errors }
 */

export const getCampaignCallRecordingsFile = async (campaignIds: string[]) => {
  try {
    const response = await api.get(
      `${
        process.env.REACT_APP_WEST_BASE_URL
      }/campaigns/calls/recording/?ids=${campaignIds.join(',')}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.message,
    };
  }
};

/**
 * getCampaignResultsFile
 * @description Requests to download results file
 * @param {string} campaignIds Campaign IDs
 * @returns {Object} { status } | { status, ...errors }
 */

export const getCampaignResultsFile = async (campaignIds: string[]) => {
  try {
    const response = await api.get(
      `${
        process.env.REACT_APP_WEST_BASE_URL
      }/campaigns/results/export/?ids=${campaignIds.join(',')}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.message,
    };
  }
};

/**
 * getDownloadCampaignContactsFile
 * @description Requests to download contacts file
 * @param {string} filePath File path returned by websocket
 * @returns {Object} { status } | { status, ...errors }
 */

export const getDownloadCampaignContactsFile = async (filePath: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/campaigns/contacts/export/download/?filePath=${filePath}`,
      {
        responseType: 'blob',
      },
    );

    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.message,
    };
  }
};
