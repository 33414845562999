import React, { useState, useEffect } from 'react';

import { UUID } from 'crypto';

import Text from 'components/interface/text';
import Input from 'components/interface/input';
import Modal from 'components/interface/modal';
import Select from 'components/interface/select';
import Button from 'components/interface/button';

import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import './styles.css';

/**
 * NewMessageModal
 * @description New message modal component
 * @param {boolean} isOpen - modal open state
 * @param {UUID} chatbotId - chatbot id
 * @param {string} integrationId - integration id
 * @param {string} recipient - recipient contact (phone number)
 * @param {boolean} isSendingCall - sending call state
 * @param {function} setChatbotId - function to set chatbot id
 * @param {function} setIntegrationId - function to set integration id
 * @param {function} setIntegrationType - function to set integration type
 * @param {function} setRecipient - function to set recipient
 * @param {function} onNext - function to go to next step
 * @param {function} onClose - function to close modal
 * @returns {TSX.Element} NewMessageModal component
 */

type NewMessageModalProps = {
  isOpen: boolean;
  chatbotId?: UUID;
  integrationId?: string;
  recipient?: string;
  isSendingCall?: boolean;
  setChatbotId: (chatbotId: UUID) => void;
  setIntegrationId: (integrationId: string) => void;
  setIntegrationType: (integrationType: string) => void;
  setRecipient: (recipient: string) => void;
  onNext: () => void;
  onClose: () => void;
};

const NewMessageModal: React.FC<NewMessageModalProps> = ({
  isOpen,
  chatbotId,
  integrationId,
  recipient,
  isSendingCall,
  setChatbotId,
  setIntegrationId,
  setIntegrationType,
  setRecipient,
  onNext,
  onClose,
}) => {
  const { chatbotsData } = useChatbot();

  const [chatbotOptions, setChatbotOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [integrationOptions, setIntegrationOptions] = useState<
    Array<{ type: string; value: string; label: string }>
  >([]);

  useEffect(() => {
    if (chatbotsData) {
      const options = Object.values(chatbotsData).map((chatbot) => ({
        value: chatbot.id,
        label: chatbot.nickname,
      }));

      setChatbotOptions(options);
    }
  }, [chatbotsData]);

  useEffect(() => {
    if (chatbotId) {
      const options: Record<
        string,
        { type: string; value: string; label: string }
      > = {};

      for (const integration of Object.values(
        chatbotsData[chatbotId]?.whatsappIntegrations || {},
      )) {
        options[integration.id] = {
          type: 'whatsapp',
          value: String(integration.id),
          label: `WhatsApp (${integration.whatsappNumberId})`,
        };
      }

      for (const integration of Object.values(
        chatbotsData[chatbotId]?.phoneCallIntegrations || {},
      )) {
        options[integration.id] = {
          type: 'phoneCall',
          value: String(integration.id),
          label: 'Llamada',
        };
      }

      setIntegrationOptions(Object.values(options));
    } else {
      setIntegrationOptions([]);
    }
  }, [chatbotId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Nuevo mensaje'}>
      <Text variant={'b3'}>Asistente</Text>
      <Select
        placeholder={'Selecciona un asistente'}
        options={chatbotOptions}
        onChange={(e) => setChatbotId(e.target.value as UUID)}
      />
      <div style={{ height: 8 }} />
      <Text variant={'b3'}>Integración</Text>
      <Select
        placeholder={'Selecciona una integración'}
        options={integrationOptions}
        isDisabled={!chatbotId}
        onChange={(e) => {
          setIntegrationId(e.target.value);
          setIntegrationType(
            integrationOptions.find((option) => option.value === e.target.value)
              ?.type || '',
          );
        }}
      />
      <div style={{ height: 8 }} />
      <Text variant={'b3'}>Destinatario</Text>
      <Input
        type={'tel'}
        placeholder={'Número de teléfono'}
        isDisabled={!chatbotId || !integrationId}
        onChange={(e) => setRecipient(e.target.value)}
      />
      <div style={{ height: 16 }} />
      <Button
        label={'Siguiente'}
        isDisabled={
          !chatbotId ||
          !integrationId ||
          (recipient?.length ?? 0) !== 10 ||
          isSendingCall
        }
        isLoading={isSendingCall}
        onClick={onNext}
      />
    </Modal>
  );
};

export default NewMessageModal;
