import React from 'react';

import Text from 'components/interface/text';

import './styles.css';

type JobTrackerOverflowProps = {
  jobOverflow: number;
};

/**
 * JobTrackerOverflow
 * @description Displays the number of jobs that overflows the tracker
 * @returns {TSX.Element} JobTrackerOverflow component
 */
const JobTrackerOverflow: React.FC<JobTrackerOverflowProps> = ({
  jobOverflow,
}) => {
  return (
    <div className={`job-tracker-overflow`}>
      <Text variant={'b3'} color={'var(--gray)'}>
        {jobOverflow} más en espera
      </Text>
    </div>
  );
};

export default JobTrackerOverflow;
