import React, { InputHTMLAttributes } from 'react';

import './styles.css';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
  isChecked?: boolean | undefined;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Checkbox
 * @description Description of Checkbox
 * @param {boolean} defaultChecked - Default value
 * @param {boolean} isChecked - Checked value
 * @param {boolean} isDisabled - Is disabled
 * @param {function} onChange - On change function
 * @returns {TSX.Element} Checkbox component
 */

const Checkbox: React.FC<CheckboxProps> = ({
  defaultChecked = false,
  isChecked = undefined,
  isDisabled = false,
  onChange = () => {},
}) => {
  return (
    <div className={'checkbox'}>
      <label className="checkbox-container">
        {isChecked === undefined ? (
          <input
            type={'checkbox'}
            defaultChecked={defaultChecked}
            disabled={isDisabled}
            onChange={onChange}
          />
        ) : (
          <input
            type={'checkbox'}
            checked={isChecked}
            disabled={isDisabled}
            onChange={onChange}
          />
        )}
        <span className="checkbox-custom"></span>
      </label>
    </div>
  );
};

export default Checkbox;
