/**
 * getEndUserInitials
 * @param {string} displayName
 * @description Function that returns the two first initials of the end user for profile picture placeholder
 * @returns {string}
 * @example
 * getEndUserInitials('John Doe') // JD
 * getEndUserInitials('John') // J
 * getEndUserInitials('John Doe Smith') // JD
 */
export const getEndUserInitials = (displayName: string) => {
  let initials = displayName
    .split(' ')
    .map((name) => name.charAt(0))
    .join('');

  if (initials.length > 2) {
    initials = initials.charAt(0) + initials.charAt(initials.length - 1);
  }

  return initials;
};

/**
 * Processes message content to remove <break/> tags
 * @param content - The message content string
 * @returns String with breaks removed
 */
export const processMessageContent = (content: string) => {
  if (!content) return null;

  return content.replace(/<break.*?\/>/g, '');
};
