import React, { useRef, useEffect, useState } from 'react';

import Text from 'components/interface/text';
import AudioPlayer from 'components/interface/audioPlayer';

import { type VoiceData } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

const GENDER_MAP = {
  male: 'Masculina',
  female: 'Femenina',
  neutral: 'Neutral',
};

const AGE_MAP = {
  young: 'Joven',
  middle_aged: 'Edad Mediana',
  old: 'Adulto Mayor',
};

const ACCENT_MAP = {
  american: 'Americano',
  mexican: 'Mexicano',
};

type VoiceCardProps = {
  voice: VoiceData;
  isSelected: boolean;
  setSelectedVoiceId: (voiceId: string) => void;
};

const VoiceCard: React.FC<VoiceCardProps> = ({
  voice,
  isSelected,
  setSelectedVoiceId,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    setSelectedVoiceId(voice.id);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    if (isPlaying) {
      const playPromise = videoElement.play();

      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.log('Video playback error:', error);
        });
      }
    } else {
      if (!videoElement.paused) {
        videoElement.pause();
      }
    }
  }, [isPlaying]);

  return (
    <div
      className={`voice-card ${
        isSelected || isPlaying ? 'voice-card-selected' : ''
      }`}
      onClick={handleClick}
    >
      <div className={'voice-card-header'}>
        <div className={'voice-card-header-left'}>
          <div className={'voice-card-header-avatar'}>
            <video
              ref={videoRef}
              width={'48px'}
              height={'48px'}
              muted
              loop
              playsInline
            >
              <source src={voice.avatarUrl} type={'video/webm'} />
            </video>
          </div>
        </div>
        <div className={'voice-card-header-right'}>
          <Text variant={'h8'}>{voice.name}</Text>
          <div className={'voice-card-header-details'}>
            <div className={'voice-card-header-details-item'}>
              <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
                {GENDER_MAP[voice.gender as keyof typeof GENDER_MAP]}
              </Text>
            </div>
            <div className={'voice-card-header-details-item'}>
              <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
                {AGE_MAP[voice.age as keyof typeof AGE_MAP]}
              </Text>
            </div>
            <div className={'voice-card-header-details-item'}>
              <Text variant={'b3'} color={'var(--gray)'} numberOfLines={1}>
                Acento {ACCENT_MAP[voice.accent as keyof typeof ACCENT_MAP]}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className={'voice-card-body'}>
        <AudioPlayer
          url={voice.demoUrl}
          isCallRecording={false}
          allowDownload={false}
          isPlayingExternal={isPlaying}
          isPlayingCallback={setIsPlaying}
        />
      </div>
    </div>
  );
};

export default VoiceCard;
