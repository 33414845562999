import { JobState } from './JobProvider';

export type JobTypes = 'SAVE_JOBS';

type JobAction = {
  type: JobTypes;
  payload?: any;
};

const jobReducer = (state: JobState, action: JobAction) => {
  const { type, payload } = action;

  switch (type) {
    case 'SAVE_JOBS':
      return {
        ...state,
        jobs: payload,
      };
    default:
      return state;
  }
};

export default jobReducer;
