import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import UserProvider from 'features/user/providers/UserProvider';
import JobProvider from 'features/jobs/providers/JobProvider';
import InboxProvider from 'features/inbox/providers/InboxProvider';
import OrganizationProvider from 'features/organization/providers/OrganizationProvider';
import ChatbotProvider from 'features/chatbots/providers/ChatbotProvider';
import EndUserProvider from 'features/endUsers/providers/EndUserProvider';
import CampaignProvider from 'features/campaigns/providers/CampaignProvider';
import DashboardProvider from 'features/dashboard/providers/DashboardProvider';
import NotificationProvider from 'features/notifications/providers/NotificationProvider';
import ApiProvider from 'features/api/providers/ApiProvider';

import DashboardSidebar from 'features/dashboard/components/sidebar';
import PermissionGate from 'features/permissions/components/PermissionGate';

import JobTrackerDisplayer from 'features/jobs/components/jobTrackerDisplayer';

import DashboardView from 'features/dashboard/views/DashboardView';
import InboxView from 'features/dashboard/views/InboxView';
import ContactsView from 'features/dashboard/views/ContactsView';
import ChatbotView from 'features/dashboard/views/ChatbotView';
import ChatbotsView from 'features/dashboard/views/ChatbotsView';
import CampaignView from 'features/dashboard/views/CampaignView';
import CampaignsView from 'features/dashboard/views/CampaignsView';
import CampaignFormView from 'features/dashboard/views/CampaignFormView';
import UsageView from 'features/dashboard/views/UsageView';
import OrganizationView from 'features/dashboard/views/OrganizationView';
import IntegrationsView from 'features/dashboard/views/IntegrationsView';
import ApiKeysView from 'features/dashboard/views/ApiKeysView';
import SettingsView from 'features/dashboard/views/SettingsView';
import NotFoundView from 'views/NotFoundView';

import { useAuth } from 'features/auth/hooks/useAuth';

const DashboardRouter = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/login', { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <UserProvider>
      <JobProvider>
        <OrganizationProvider>
          <ChatbotProvider>
            <EndUserProvider>
              <CampaignProvider>
                <ApiProvider>
                  <InboxProvider>
                    <NotificationProvider>
                      <DashboardProvider>
                        <div className={'dashboard'}>
                          <JobTrackerDisplayer />
                          <DashboardSidebar />
                          <Routes>
                            <Route path={'/'} element={<DashboardView />} />
                            <Route path={'/inbox'} element={<InboxView />} />
                            <Route
                              path={'/contacts'}
                              element={<ContactsView />}
                            />
                            <Route
                              path={'/assistants'}
                              element={<ChatbotsView />}
                            />
                            <Route
                              path={'/assistants/:id'}
                              element={<ChatbotView />}
                            />
                            <Route
                              path={'/campaigns'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['campaigns.read']}
                                >
                                  <CampaignsView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/campaigns/:id'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['campaigns.read']}
                                >
                                  <CampaignView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/campaigns/:id/edit'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['campaigns.update']}
                                >
                                  <CampaignFormView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/campaigns/new'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['campaigns.create']}
                                >
                                  <CampaignFormView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/usage'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['billing.read']}
                                >
                                  <UsageView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/organization'}
                              element={<OrganizationView />}
                            />
                            <Route
                              path={'/integrations'}
                              element={<IntegrationsView />}
                            />
                            <Route
                              path={'/api'}
                              element={
                                <PermissionGate
                                  requiredPermissions={['api.read']}
                                >
                                  <ApiKeysView />
                                </PermissionGate>
                              }
                            />
                            <Route
                              path={'/settings'}
                              element={<SettingsView />}
                            />
                            <Route path={'*'} element={<NotFoundView />} />
                          </Routes>
                        </div>
                      </DashboardProvider>
                    </NotificationProvider>
                  </InboxProvider>
                </ApiProvider>
              </CampaignProvider>
            </EndUserProvider>
          </ChatbotProvider>
        </OrganizationProvider>
      </JobProvider>
    </UserProvider>
  );
};

export default DashboardRouter;
