import React, { useState } from 'react';

import Icon from 'components/interface/icon';
import Button from 'components/interface/button';

import { useCampaign } from 'features/campaigns/hooks/useCampaign';

type CampaignCallRecordingsDownloadProps = {
  campaignId: string;
};

/**
 * CampaignCallRecordingsDownload
 * @description Button that opens a modal to download calls to a campaign
 * @param {string} campaignId - Campaign id
 * @returns {TSX.Element} CampaignCallRecordingsDownload component
 */

const CampaignCallRecordingsDownload: React.FC<
  CampaignCallRecordingsDownloadProps
> = ({ campaignId }) => {
  const { exportCampaignCallRecordingsFile } = useCampaign();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await exportCampaignCallRecordingsFile([campaignId]);
    setIsLoading(false);
  };

  return (
    <>
      <Button
        label={'Descargar Grabaciones'}
        variant={'secondary'}
        icon={<Icon name={'record'} />}
        isLoading={isLoading}
        onClick={handleDownload}
      />
    </>
  );
};

export default CampaignCallRecordingsDownload;
