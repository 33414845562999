import React from 'react';

import { JobContext, JobData } from '../providers/JobProvider';

/**
 * @hook useJob
 * @description Custom hook to use JobContext
 * @returns {Object} JobContext
 */
export const useJob = () => {
  const { jobs, saveJobs } = React.useContext(JobContext);

  const receiveJobTrackerUpdate = (job: JobData) => {
    const auxJobs = { ...jobs };

    if (auxJobs[job.id]) {
      const auxJob = auxJobs[job.id];
      auxJob.status = job.status || auxJob.status;
      auxJob.progress = job.progress || auxJob.progress;
      auxJob.progressMessage = job.progressMessage || auxJob.progressMessage;
      auxJobs[job.id] = auxJob;
    } else {
      auxJobs[job.id] = job;
      auxJobs[job.id].firstSeen = new Date().toISOString();
      auxJobs[job.id].isHidden = false;
    }

    saveJobs(auxJobs);
  };

  const hideJob = (jobId: string) => {
    const auxJobs = { ...jobs };
    auxJobs[jobId].isHidden = true;
    saveJobs(auxJobs);
  };

  return { jobs, receiveJobTrackerUpdate, hideJob };
};
