import api from 'api';

import { UUID } from 'crypto';

/**
 * fetchChatbotsData
 * @description Function that gets chatbots data
 * @returns {Object} { status, data: {chatbotsData} } | { status, ...errors }
 */

export const fetchChatbotsData = async () => {
  try {
    const response = await api.get(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/',
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postChatbot
 * @description Function that creates chatbot
 * @param {string} nickname
 * @param {string} name
 * @returns {Object} { status, data: {chatbotsData} } | { status, ...errors }
 */
export const postChatbot = async (nickname: string, name: string) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/',
      {
        nickname,
        name,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteChatbot
 * @description Function that deletes chatbot
 * @param {UUID} id
 * @returns {Object} { status} | { status, ...errors }
 */

export const deleteChatbot = async (id: UUID) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotGeneralData
 * @description Function that updates chatbot general data
 * @param {UUID} id
 * @param {string} nickname
 * @param {number} threshold
 * @param {boolean} isActive
 * @returns {Object} { status, data: {chatbotsData} } | { status, ...errors }
 */

export const putChatbotGeneralData = async (
  id: UUID,
  nickname: string,
  threshold: number,
  isActive: boolean,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/general/',
      {
        id,
        nickname,
        threshold,
        isActive,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotBehaviorData
 * @description Function that updates chatbot behavior data
 * @param {UUID} id
 * @param {string} name
 * @param {string} personality
 * @param {string} instructionsWhatsapp
 * @param {string} instructionsPhoneCall
 * @param {string} inboundGreeting
 * @param {string} objective
 * @param {string} businessName
 * @param {string} businessDescription
 * @returns {Object} { status, data: {chatbotsData} } | { status, ...errors }
 */

export const putChatbotBehaviorData = async (
  id: UUID,
  name: string,
  personality: string,
  instructionsWhatsapp: string,
  instructionsPhoneCall: string,
  inboundGreeting: string,
  objective: string,
  businessName: string,
  businessDescription: string,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/behavior/',
      {
        id,
        name,
        personality,
        instructionsWhatsapp,
        instructionsPhoneCall,
        inboundGreeting,
        objective,
        businessName,
        businessDescription,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchChatbotKnowledgeSources
 * @description Function that gets chatbot knowledge sources
 * @param {UUID} id bot id
 * @returns {Object} { status, data: {knowledgeSources} } | { status, ...errors }
 */

export const fetchChatbotKnowledgeSources = async (id: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/knowledge/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postChatbotKnowledgeSource
 * @description Function that creates chatbot knowledge source
 * @param {FormData} formData
 * @returns {Object} { status, data: {knowledgeSources} } | { status, ...errors }
 */

export const postChatbotKnowledgeSource = async (formData: FormData) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/knowledge/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotKnowledgeSource
 * @description Function that updates chatbot knowledge source
 * @param {string} id knowledge source id
 * @param {UUID} botId bot id
 * @param {string} name knowledge source name
 * @returns {Object} { status, data: {knowledgeSources} } | { status, ...errors }
 */

export const putChatbotKnowledgeSource = async (
  id: string,
  botId: UUID,
  name: string,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/knowledge/',
      {
        id,
        botId,
        name,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteChatbotKnowledgeSource
 * @description Function that deletes chatbot knowledge source
 * @param {string} id knowledge source id
 * @returns {Object} { status, data: {knowledgeSources} } | { status, ...errors }
 */

export const deleteChatbotKnowledgeSource = async (id: string) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/knowledge/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchChatbotKnowledgeChunks
 * @description Function that gets chatbot knowledge chunks
 * @param {string} id knowledge source id
 * @returns {Object} { status, data: {knowledgeChunks} } | { status, ...errors }
 */

export const fetchChatbotKnowledgeChunks = async (id: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/knowledge/chunk/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchChatbotSendableMedia
 * @description Function that gets chatbot sendable media
 * @param {UUID} id bot id
 * @returns {Object} { status, data: {sendableMedia} } | { status, ...errors }
 */

export const fetchChatbotSendableMedia = async (id: string) => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/media/?id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postChatbotSendableMedia
 * @description Function that creates chatbot sendable media
 * @param {FormData} formData
 * @returns {Object} { status, data: {sendableMedia} } | { status, ...errors }
 */

export const postChatbotSendableMedia = async (formData: FormData) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/media/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotSendableMedia
 * @description Function that updates chatbot sendable media
 * @param {FormData} formData
 * @returns {Object} { status, data: {sendableMedia} } | { status, ...errors }
 */

export const putChatbotSendableMedia = async (formData: FormData) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/media/',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteChatbotSendableMedia
 * @description Function that deletes chatbot sendable media
 * @param {UUID} botId
 * @param {string} mediaId
 * @returns {Object} { status, data: {sendableMedia} } | { status, ...errors }
 */

export const deleteChatbotSendableMedia = async (
  botId: UUID,
  mediaId: string,
) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/media/?botId=${botId}&mediaId=${mediaId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postChatbotTag
 * @description Function that creates chatbot thread tag
 * @param {UUID} botId
 * @param {string} name
 * @param {string} color
 * @param {string} trigger
 * @param {boolean} isConversion
 * @param {boolean} isAuto
 * @param {string} instructions
 * @param {boolean} notifications
 * @returns {Object} { status} | { status, ...errors }
 */

export const postChatbotTag = async (
  botId: UUID,
  name: string,
  color: string,
  trigger: string,
  isConversion: boolean,
  isAuto: boolean,
  instructions: string,
  notifications: boolean,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/tag/',
      {
        botId,
        name,
        color,
        trigger,
        isConversion,
        isAuto,
        instructions,
        notifications,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotTag
 * @description Function that updates chatbot thread tag
 * @param {string} botId
 * @param {string} tagId
 * @param {string} name
 * @param {string} color
 * @param {string} trigger
 * @param {boolean} isConversion
 * @param {boolean} isAuto
 * @param {string} instructions
 * @param {boolean} notifications
 * @returns {Object} { status} | { status, ...errors }
 */

export const putChatbotTag = async (
  botId: UUID,
  tagId: string,
  name: string,
  color: string,
  trigger: string,
  isConversion: boolean,
  isAuto: boolean,
  instructions: string,
  notifications: boolean,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/tag/',
      {
        botId,
        id: tagId,
        name,
        color,
        trigger,
        isConversion,
        isAuto,
        instructions,
        notifications,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteChatbotTag
 * @description Function that deletes chatbot thread tag
 * @param {UUID} botId
 * @param {string} tagId
 * @returns {Object} { status} | { status, ...errors }
 */

export const deleteChatbotTag = async (botId: UUID, tagId: string) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/tag/?botId=${botId}&tagId=${tagId}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
    };
  }
};

/**
 * postChatbotExtractableDataField
 * @description Function that creates chatbot extractable data field
 * @param {UUID} botId
 * @param {string} name
 * @param {string} description
 * @param {string} type
 * @param {string[]} enumValues
 * @returns {Object} { status} | { status, ...errors }
 */

export const postChatbotExtractableDataField = async (
  botId: UUID,
  name: string,
  description: string,
  type: string,
  enumValues: string[],
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/extractable-data-field/',
      {
        botId,
        name,
        description,
        type,
        enumValues,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotExtractableDataField
 * @description Function that updates chatbot extractable data field
 * @param {UUID} botId
 * @param {string} id
 * @param {string} name
 * @param {string} description
 * @param {string} type
 * @param {string[]} enumValues
 * @returns {Object} { status} | { status, ...errors }
 */

export const putChatbotExtractableDataField = async (
  botId: UUID,
  id: string,
  name: string,
  description: string,
  type: string,
  enumValues: string[],
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/extractable-data-field/',
      {
        botId,
        id,
        name,
        description,
        type,
        enumValues,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * deleteChatbotExtractableDataField
 * @description Function that deletes chatbot extractable data field
 * @param {UUID} botId
 * @param {string} id
 * @returns {Object} { status} | { status, ...errors }
 */

export const deleteChatbotExtractableDataField = async (
  botId: UUID,
  id: string,
) => {
  try {
    const response = await api.delete(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/extractable-data-field/?botId=${botId}&id=${id}`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
    };
  }
};

/**
 * putChatbotAssignees
 * @description Function that updates chatbot eligible assignees
 * @param {UUID} botId
 * @param {string[]} eligibleAssigneesIds
 * @returns {Object} { status} | { status, ...errors }
 */

export const putChatbotAssignees = async (
  botId: UUID,
  eligibleAssigneesIds: string[],
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/assignments/',
      {
        botId,
        eligibleAssigneesIds,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * postChatbotWhatsappIntegration
 * @description Function that creates chatbot whatsapp integration
 * @param {UUID} botId
 * @param {string} whatsappAccountId
 * @param {string} whatsappNumberId
 * @param {string} whatsappSecretId
 * @param {string} pageAccessToken
 * @param {boolean} botActiveDefault
 * @returns {Object} { status} | { status, ...errors }
 */

export const postChatbotWhatsappIntegration = async (
  botId: UUID,
  whatsappAccountId: string,
  whatsappNumberId: string,
  whatsappSecretId: string,
  pageAccessToken: string,
  botActiveDefault: boolean,
) => {
  try {
    const response = await api.post(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/integration/whatsapp/',
      {
        botId,
        whatsappAccountId,
        whatsappNumberId,
        whatsappSecretId,
        pageAccessToken,
        botActiveDefault,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotWhatsappIntegration
 * @description Function that updates chatbot whatsapp integration
 * @param {UUID} botId
 * @param {number} integrationId
 * @param {string} whatsappAccountId
 * @param {string} whatsappNumberId
 * @param {string} whatsappSecretId
 * @param {string} pageAccessToken
 * @param {boolean} botActiveDefault
 * @returns {Object} { status} | { status, ...errors }
 */

export const putChatbotWhatsappIntegration = async (
  botId: UUID,
  integrationId: string,
  whatsappAccountId: string,
  whatsappNumberId: string,
  whatsappSecretId: string,
  pageAccessToken: string,
  botActiveDefault: boolean,
) => {
  try {
    const response = await api.put(
      process.env.REACT_APP_WEST_BASE_URL + '/chatbot/integration/whatsapp/',
      {
        botId,
        id: integrationId,
        whatsappAccountId,
        whatsappNumberId,
        whatsappSecretId,
        pageAccessToken,
        botActiveDefault,
      },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status,
      errors: error?.response?.data,
    };
  }
};

/**
 * putChatbotPhoneCallIntegration
 * @description Function that updates chatbot phone call integration
 * @param {UUID} botId
 * @param {string} integrationId
 * @param {string} voiceId
 * @returns {Object} { status, data: {chatbotsData} } | { status, ...errors }
 */

export const putChatbotPhoneCallIntegration = async (
  botId: UUID,
  integrationId: string,
  phoneNumber: string,
  voiceId: string,
) => {
  try {
    const response = await api.put(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/integration/phone-call/`,
      { botId, id: integrationId, phoneNumber, voiceId },
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};

/**
 * fetchVoicesData
 * @description Function that gets voices data
 * @returns {Object} { status, data: {voicesData} } | { status, ...errors }
 */

export const fetchVoicesData = async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_WEST_BASE_URL}/chatbot/voices/`,
    );
    return { status: 200, data: response?.data };
  } catch (error: any) {
    return {
      status: error?.response?.status || 500,
      errors: error?.response?.data,
    };
  }
};
