import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import IconButton from 'components/interface/iconButton';
import NewMessageModal from '../threadsNewMessageModal';
import SendTemplateModal from '../sendTemplateModal';

import { useInbox } from 'features/inbox/hooks/useInbox';

import './styles.css';

/**
 * InboxThreadsNewMessageButton
 * @description Component to handle inbox threads new message button
 * @returns {TSX.Element} InboxThreadsNewMessageButton component
 */

const InboxThreadsNewMessageButton: React.FC = () => {
  const { sendCall } = useInbox();

  const [chatbotId, setChatbotId] = useState<UUID | undefined>();
  const [integrationId, setIntegrationId] = useState<string | undefined>();
  const [integrationType, setIntegrationType] = useState<string | undefined>();
  const [recipient, setRecipient] = useState<string | undefined>();

  const [chatbotIdLocked, setChatbotIdLocked] = useState<UUID | undefined>();
  const [integrationIdLocked, setIntegrationIdLocked] = useState<
    string | undefined
  >();
  const [recipientLocked, setRecipientLocked] = useState<string | undefined>();

  const [isNewMessageModalOpen, setIsNewMessageModalOpen] =
    useState<boolean>(false);
  const [isSendTemplateModalOpen, setIsSendTemplateModalOpen] =
    useState<boolean>(false);
  const [isSendingCall, setIsSendingCall] = useState<boolean>(false);

  useEffect(() => {
    if (!isNewMessageModalOpen) {
      setChatbotId(undefined);
      setIntegrationId(undefined);
      setRecipient(undefined);
    }
  }, [isNewMessageModalOpen]);

  const handleNextStep = () => {
    if (chatbotId && integrationId && recipient) {
      if (integrationType === 'phoneCall') {
        handleSendCall();
      } else {
        setChatbotIdLocked(chatbotId);
        setIntegrationIdLocked(integrationId);
        setRecipientLocked(recipient);
        setIsNewMessageModalOpen(false);
        setIsSendTemplateModalOpen(true);
      }
    }
  };

  const handleSendCall = async () => {
    if (chatbotId && integrationId && recipient) {
      setIsSendingCall(true);
      const response = await sendCall(chatbotId, integrationId, recipient);

      if (response.status === 200 && response.data?.success == true) {
        toast.success('Llamada enviada correctamente');
      } else {
        toast.error('Error al enviar la llamada');
      }

      setIsNewMessageModalOpen(false);
      setIsSendingCall(false);
    }
  };

  return (
    <>
      <div className={'inbox-threads-new-message'}>
        <IconButton
          iconName={'send'}
          iconColor={'var(--gray)'}
          hint={'Redactar'}
          onClick={() => setIsNewMessageModalOpen(true)}
        />
      </div>
      <NewMessageModal
        isOpen={isNewMessageModalOpen}
        chatbotId={chatbotId}
        integrationId={integrationId}
        recipient={recipient}
        setChatbotId={setChatbotId}
        setIntegrationId={setIntegrationId}
        setIntegrationType={setIntegrationType}
        setRecipient={setRecipient}
        isSendingCall={isSendingCall}
        onNext={handleNextStep}
        onClose={() => setIsNewMessageModalOpen(false)}
      />
      <SendTemplateModal
        isOpen={isSendTemplateModalOpen}
        chatbotId={chatbotIdLocked}
        integrationId={integrationIdLocked}
        recipient={recipientLocked}
        onClose={() => setIsSendTemplateModalOpen(false)}
      />
    </>
  );
};

export default InboxThreadsNewMessageButton;
