import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import { UUID } from 'crypto';

import { InboxContext } from '../providers/InboxProvider';

import { useUser } from 'features/user/hooks/useUser';
import { useChatbot } from 'features/chatbots/hooks/useChatbot';

import {
  fetchThreads,
  fetchThreadMessages,
  fetchMessageMediaBinary,
  fetchCallRecording,
  fetchS3File,
  postMessageMedia,
  postAssignThreadTag,
  postUnassignThreadTag,
  postThreadEndUserInfo,
  postThreadChatbotToggle,
  postSendMessage,
  postSendTemplate,
  postSendCall,
  postMessageRead,
  postThreadAssign,
  getThreadTranscriptFile,
} from '../functions/inboxFunctions';

import {
  type Thread,
  type WebsocketMessage,
  type IntegrationType,
} from '../types/inboxTypes';

/**
 * @hook useInbox
 * @description This hook is used to access the inbox context and functions
 * @returns {Object} The inbox context
 */
export const useInbox = () => {
  const {
    inbox,
    inboxFilters,
    inboxStatus,
    inboxPreferences,
    fetchingThreads,
    saveInbox,
    saveInboxFilters,
    saveInboxStatus,
    saveInboxPreferences,
    saveFetchingThreads,
  } = React.useContext(InboxContext);
  const { userDisplayData } = useUser();
  const { chatbotsData } = useChatbot();

  const threadAbortController = useRef<AbortController>(new AbortController());
  const threadMessagesAbortController = useRef<AbortController>(
    new AbortController(),
  );

  /**
   * @function updateInboxStatus
   * @description This function is used to update the inbox status
   * @param {boolean} webSocketConnected - The web socket connection status
   * @param {boolean} webSocketConnecting - The web socket connection status
   * @returns {void}
   */
  const updateInboxStatus = (
    webSocketConnected: boolean,
    webSocketConnecting: boolean,
  ) => {
    saveInboxStatus({ webSocketConnected, webSocketConnecting });
  };

  /**
   * @function filterToggleChannel
   * @description This function is used to toggle the channel filter
   * @param {IntegrationType} channel - The channel value
   * @returns {void}
   */
  const filterToggleChannel = (channel: IntegrationType) => {
    const inboxFiltersAux = { ...inboxFilters };

    if (inboxFiltersAux.selectedChannels.includes(channel)) {
      inboxFiltersAux.selectedChannels =
        inboxFiltersAux.selectedChannels.filter((c) => c !== channel);
    } else {
      inboxFiltersAux.selectedChannels = [
        ...inboxFiltersAux.selectedChannels,
        channel,
      ];
    }

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterToggleChatbot
   * @description This function is used to toggle the chatbot filter
   * @param {UUID} chatbotId - The chatbot id
   * @returns {void}
   */
  const filterToggleChatbot = (chatbotId: UUID) => {
    const inboxFiltersAux = { ...inboxFilters };

    if (inboxFiltersAux.selectedChatbots.includes(chatbotId)) {
      inboxFiltersAux.selectedChatbots =
        inboxFiltersAux.selectedChatbots.filter(
          (chatbot) => chatbot !== chatbotId,
        );
      // Deselect this chatbot tags
      const chatbotTagsIds = Object.values(chatbotsData[chatbotId].tags).map(
        (tag) => tag.id,
      );
      inboxFiltersAux.selectedTags = inboxFiltersAux.selectedTags.filter(
        (tag) => !chatbotTagsIds.includes(tag),
      );
    } else {
      inboxFiltersAux.selectedChatbots = [
        ...inboxFiltersAux.selectedChatbots,
        chatbotId,
      ];
    }

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterToggleTag
   * @description This function is used to toggle the tag filter
   * @param {string} tagId - The tag id
   * @returns {void}
   */
  const filterToggleTag = (tagId: string) => {
    const inboxFiltersAux = { ...inboxFilters };

    if (inboxFiltersAux.selectedTags.includes(tagId)) {
      inboxFiltersAux.selectedTags = inboxFiltersAux.selectedTags.filter(
        (tag) => tag !== tagId,
      );
    } else {
      inboxFiltersAux.selectedTags = [...inboxFiltersAux.selectedTags, tagId];
    }

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterSetOrderBy
   * @description This function is used to set the order by filter
   * @param {string} orderBy - The order by value
   * @returns {void}
   */
  const filterSetOrderBy = (orderBy: 'asc' | 'desc') => {
    const inboxFiltersAux = { ...inboxFilters };

    inboxFiltersAux.orderBy = orderBy;

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterSetShowUnreplied
   * @description This function is used to set the show unreplied filter
   * @param {boolean} showUnreplied - The show unreplied value
   * @returns {void}
   */
  const filterSetShowUnreplied = (showUnreplied: boolean) => {
    const inboxFiltersAux = { ...inboxFilters };

    inboxFiltersAux.showUnreplied = showUnreplied;

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterSetShowUnreadOnly
   * @description This function is used to set the show unread only filter
   * @param {boolean} showUnreadOnly - The show unread only value
   * @returns {void}
   */
  const filterSetShowUnreadOnly = (showUnreadOnly: boolean) => {
    const inboxFiltersAux = { ...inboxFilters };

    inboxFiltersAux.showUnreadOnly = showUnreadOnly;

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterSetShowAssignedOnly
   * @description This function is used to set the show assigned only filter
   * @param {boolean} showAssignedOnly - The show assigned only value
   * @returns {void}
   */
  const filterSetShowAssignedOnly = (showAssignedOnly: boolean) => {
    const inboxFiltersAux = { ...inboxFilters };

    inboxFiltersAux.showAssignedOnly = showAssignedOnly;

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function filterSetSearch
   * @description This function is used to set the search filter
   * @param {string} search - The search value
   * @returns {void}
   */
  const filterSetSearch = (search: string) => {
    const inboxFiltersAux = { ...inboxFilters };

    inboxFiltersAux.search = search;

    saveInboxFilters(inboxFiltersAux);
  };

  /**
   * @function getThreads
   * @description This function is used to fetch inbox threads
   * @param {reset} boolean - resets the page to 0 and clears the threads
   * @returns {void}
   */
  const getThreads = async (reset: boolean) => {
    // TODO: Fix thread fetching bug
    // Bug description:
    // When new threads are fetched and the access token is expired,
    // the request fails and fetchingThreads stays true, so the user
    // can't fetch threads anymore.
    // * To avoid this the early return was commented, but it's not a
    // * good solution.
    // Update: Incremented accessToken expiration time to 6 hours (fix for now)

    threadAbortController.current.abort();
    threadAbortController.current = new AbortController();

    saveFetchingThreads(true);

    const inboxAux = { ...inbox };
    const threadsAux: { [key: number]: Thread } = reset ? {} : inbox.threads;

    if (reset) {
      inboxAux.page = 0;
      inboxAux.hasMore = true;
    }

    await fetchThreads(
      inboxFilters.selectedChatbots,
      inboxFilters.selectedTags,
      inboxFilters.orderBy,
      inboxFilters.selectedChannels,
      inboxFilters.showUnreplied,
      inboxFilters.showUnreadOnly,
      inboxFilters.showAssignedOnly,
      inboxFilters.search,
      inboxAux.page,
      threadAbortController.current.signal,
    )
      .then((response) => {
        if (response.status === 200) {
          response.data.threads.forEach((thread: Thread) => {
            threadsAux[thread.id] = thread;
            threadsAux[thread.id].endUser = thread.endUser;
            // Set conversationId as chatbotId-endUserId
            threadsAux[
              thread.id
            ].conversationId = `${thread.chatbotId}-${thread.endUser.id}`;
          });
          inboxAux.page = inboxAux.page + 1;
          inboxAux.hasMore = response.data.hasMore;
        }
        inboxAux.threads = threadsAux;

        saveInbox(inboxAux);
        saveFetchingThreads(false);
      })
      .catch((error) => {
        if (error.code !== 'ERR_CANCELED') saveFetchingThreads(false);
      });
  };

  /**
   * @function openThread
   * @description This function is used to open a thread
   * @param {number} threadId - The thread id
   * @returns {void}
   */
  const openThread = (threadId: number) => {
    if (threadId === inbox.activeThread?.id) return;
    const inboxAux = { ...inbox };

    inboxAux.threads[threadId].unreadMessages = 0;
    inboxAux.activeThread = inbox.threads[threadId];

    saveInbox(inboxAux);
  };

  /**
   * @function getThreadMessages
   * @description This function is used to fetch active thread messages
   * @returns {void}
   */
  const getThreadMessages = async () => {
    if (!inbox.activeThread) return;

    threadMessagesAbortController.current.abort();
    threadMessagesAbortController.current = new AbortController();

    const threadAux = inbox.activeThread;

    await fetchThreadMessages(
      threadAux.id,
      threadMessagesAbortController.current.signal,
    ).then((response) => {
      if (response.status === 200) {
        threadAux.messages = response.data;
      }

      const inboxAux = { ...inbox };
      inboxAux.activeThread = threadAux;

      saveInbox(inboxAux);
    });
  };

  /**
   * @function setSelectedThreads
   * @description This function is used to set the selected threads
   * @param {number[]} threadIds - The thread ids
   * @returns {void}
   */
  const setSelectedThreadIds = (threadIds: number[]) => {
    saveInbox({ ...inbox, selectedThreadsIds: threadIds });
  };

  /**
   * @function getMessageMediaBinary
   * @description This function is used to fetch a message's media binary data
   * @param {UUID} chatbotId - The chatbot id
   * @param {string} integrationId - The integration id
   * @param {string} mediaId - The media id
   * @returns { binary: string, mimeType: string } The message media binary data
   */
  const getMessageMediaBinary = async (
    chatbotId: UUID,
    integrationId: string,
    mediaId: string,
  ) => {
    const response = await fetchMessageMediaBinary(
      chatbotId,
      integrationId,
      mediaId,
    );

    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  /**
   * @function getCallRecording
   * @description This function is used to fetch a call recording
   * @param {string} callId - The call id
   * @returns { string } The call recording url
   */
  const getCallRecording = async (callId: string) => {
    const response = await fetchCallRecording(callId);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  /**
   * @function getS3File
   * @description This function is used to fetch a file from S3
   * @param {string} url - The file url
   * @returns { string } The file url
   */
  const getS3File = async (url: string) => {
    const response = await fetchS3File(url);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  /**
   * @function uploadMessageMedia
   * @description This function is used to upload a message media
   * @param {UUID} chatbotId - The chatbot id
   * @param {string} integrationId - The integration id
   * @param {FormData} formData - The media form data
   * @returns { mediaId: string, name: string } The uploaded media data
   */
  const uploadMessageMedia = async (
    chatbotId: UUID,
    integrationId: string,
    formData: FormData,
  ) => {
    formData.append('botId', chatbotId);
    formData.append('integrationId', integrationId);
    const response = await postMessageMedia(formData);

    if (response.status === 200) {
      return response.data;
    }

    return null;
  };

  /**
   * @function assignThreadTag
   * @description This function is used to assign a tag to a thread
   * @param {number} threadId - The thread id
   * @param {string} tagId - The tag id
   * @returns {void}
   */
  const assignThreadTag = async (threadId: number, tagId: string) => {
    const response = await postAssignThreadTag(threadId, tagId);

    if (response.status === 200) {
      const inboxAux = { ...inbox };
      const threadAux = inboxAux.threads[threadId];
      const relatedThreadsAux = Object.values(inboxAux.threads).filter(
        (t: Thread) =>
          t.conversationId === threadAux.conversationId && t.id !== threadId,
      );

      if (threadAux.tags.includes(tagId)) return;

      threadAux.tags = [...threadAux.tags, tagId];

      inboxAux.threads[threadId] = threadAux;

      relatedThreadsAux.forEach((t: Thread) => {
        t.tags = [...t.tags, tagId];
        inboxAux.threads[t.id] = t;
      });

      if (inboxAux.activeThread?.id === threadAux.id) {
        inboxAux.activeThread = threadAux;
      }

      saveInbox(inboxAux);
    }
  };

  /**
   * @function unassignThreadTag
   * @description This function is used to unassign a tag from a thread
   * @param {number} threadId - The thread id
   * @param {string} tagId - The tag id
   * @returns {void}
   */
  const unassignThreadTag = async (threadId: number, tagId: string) => {
    const response = await postUnassignThreadTag(threadId, tagId);

    if (response.status === 200) {
      const inboxAux = { ...inbox };
      const threadAux = inboxAux.threads[threadId];
      const relatedThreadsAux = Object.values(inboxAux.threads).filter(
        (t: Thread) =>
          t.conversationId === threadAux.conversationId && t.id !== threadId,
      );

      threadAux.tags = threadAux.tags.filter((tag) => tag !== tagId);

      inboxAux.threads[threadId] = threadAux;

      relatedThreadsAux.forEach((t: Thread) => {
        t.tags = t.tags.filter((tag) => tag !== tagId);
        inboxAux.threads[t.id] = t;
      });

      if (inboxAux.activeThread?.id === threadAux.id) {
        inboxAux.activeThread = threadAux;
      }

      saveInbox(inboxAux);
    }
  };

  /**
   * @function updateEndUserInfo
   * @description This function is used to post the end user info
   * @param {number} threadId - The thread id
   * @param {string} info - The end user info
   * @returns {boolean} - success
   */
  const updateEndUserInfo = async (threadId: number, info: string) => {
    const response = await postThreadEndUserInfo(threadId, info);

    if (response.status === 200) {
      const inboxAux = { ...inbox };
      const threadAux = inboxAux.threads[threadId];

      threadAux.endUser.info = info;

      inboxAux.threads[threadId] = threadAux;

      if (inboxAux.activeThread?.id === threadAux.id) {
        inboxAux.activeThread = threadAux;
      }

      saveInbox(inboxAux);

      return true;
    }

    return false;
  };

  /**
   * @function toggleDetailsExpanded
   * @description This function is used to toggle the details expanded state
   * @returns {void}
   */
  const toggleDetailsExpanded = () => {
    const inboxPreferencesAux = { ...inboxPreferences };

    inboxPreferencesAux.detailsExpanded = !inboxPreferences.detailsExpanded;

    saveInboxPreferences(inboxPreferencesAux);
  };

  /**
   * @function toggleThreadChatbot
   * @description This function is used to toggle the thread chatbot automatic replies
   * @returns {void}
   */
  const toggleThreadChatbot = async () => {
    if (!inbox.activeThread) return;

    const threadAux = inbox.activeThread;

    const response = await postThreadChatbotToggle(threadAux.id);

    if (response.status === 200) {
      threadAux.active = response.data.active;
    }

    const inboxAux = { ...inbox };
    inboxAux.activeThread = threadAux;

    saveInbox(inboxAux);
  };

  /**
   * @function sendMessage
   * @description This function is used to send a message
   * @param {string} type - The message type
   * @param {string} content - The message content
   * @param {string} media - The message media (id etc)
   * @returns {void}
   */
  const sendMessage = async (
    type: 'text' | 'image' | 'document',
    content?: string,
    media?: string,
  ) => {
    if (!inbox.activeThread) return;

    await postSendMessage(
      inbox.activeThread.chatbotId,
      inbox.activeThread.id,
      type,
      content ?? null,
      media ?? null,
    );
  };

  /**
   * @function sendTemplate
   * @description This function is used to send a template
   * @param {UUID} chatbotId - The chatbot id
   * @param {string} integrationId - The integration id
   * @param {string} recipient - The recipient's phone number
   * @param {Template} template - The template json object
   * @param {UUID} endUserId - The end user id
   */
  const sendTemplate = async (
    chatbotId: UUID,
    integrationId: string,
    recipient: string,
    template: any,
    endUserId?: UUID,
  ) => {
    return await postSendTemplate(
      chatbotId,
      integrationId,
      recipient,
      template,
      endUserId,
    );
  };

  /**
   * @function sendCall
   * @description This function is used to send a call
   * @param {UUID} chatbotId - The chatbot id
   * @param {string} integrationId - The integration id
   * @param {string} recipient - The recipient's phone number
   * @param {UUID} endUserId - The end user id
   */
  const sendCall = async (
    chatbotId: UUID,
    integrationId: string,
    recipient: string,
    endUserId?: UUID,
  ) => {
    return await postSendCall(chatbotId, integrationId, recipient, endUserId);
  };

  /**
   * @function checkMessageMatchesFilters
   * @description This function is used to check if a message matches the current inbox filters
   * @param {WebsocketMessage} payload - The message payload
   * @returns {boolean} - matchesFilters
   */
  const checkMessageMatchesFilters = (payload: WebsocketMessage) => {
    // Checks if message matches the current inbox filters
    // - assigned (if showAssignedOnly)
    const assigneeFilter =
      !inboxFilters.showAssignedOnly ||
      payload.thread.assignees.includes(userDisplayData.id);
    // - selectedChannels
    const channelFilter = inboxFilters.selectedChannels.includes(
      payload.thread.integrationType,
    );
    // - selectedChatbots
    const chatbotFilter = inboxFilters.selectedChatbots.includes(
      payload.chatbotId,
    );
    // - selectedTags
    const tagFilter =
      inboxFilters.selectedTags.some((tag) =>
        payload.thread.tags.includes(tag),
      ) || inboxFilters.selectedTags.length === 0;
    // - empty search
    const searchFilter = inboxFilters.search.length === 0;

    return (
      assigneeFilter &&
      channelFilter &&
      chatbotFilter &&
      tagFilter &&
      searchFilter
    );
  };

  /**
   * @function recieveMessage
   * @description This function is used to recieve a message from websocket
   * @param {WebsocketMessage} payload - The message payload
   * @returns {void}
   */
  const recieveMessage = (payload: WebsocketMessage) => {
    const inboxAux = { ...inbox };
    const activeThreadAux = inboxAux.activeThread;

    // Append message to thread:
    // - If message belongs to active thread
    // - Is not already in the messages array
    if (activeThreadAux?.id === payload.threadId) {
      if (activeThreadAux.messages.find((m) => m.id === payload.id)) {
        activeThreadAux.messages = activeThreadAux.messages.map((m) =>
          m.id === payload.id ? payload.message : m,
        );
      } else {
        markMessageRead(payload.id, payload.chatbotId);

        activeThreadAux.messages = [
          ...activeThreadAux.messages,
          payload.message,
        ];
      }

      inboxAux.activeThread = activeThreadAux;
    }

    const threadsAux = { ...inboxAux.threads };

    // Append/Replace thread to threads if matches filters:
    const matchesFilters = checkMessageMatchesFilters(payload);
    if (matchesFilters) {
      if (!threadsAux[payload.threadId]) {
        // If thread is not in threads, append it
        threadsAux[payload.threadId] = payload.thread;
        threadsAux[payload.threadId].endUser = payload.thread.endUser;
        // Set conversationId as chatbotId-endUserId
        threadsAux[
          payload.threadId
        ].conversationId = `${payload.chatbotId}-${payload.thread.endUser.id}`;
      } else {
        // If thread is in threads, replace some properties
        threadsAux[payload.threadId].endUser = payload.thread.endUser;
        threadsAux[payload.threadId].endUser.displayName =
          payload.thread.endUser.displayName;
        threadsAux[payload.threadId].latestMessage = payload.message;
        threadsAux[payload.threadId].updatedAt = payload.thread.updatedAt;
        threadsAux[payload.threadId].active = payload.thread.active;
        threadsAux[payload.threadId].tags = payload.thread.tags;

        // If thread not activeThread, increment unreadMessages
        if (inboxAux.activeThread?.id !== payload.threadId) {
          threadsAux[payload.threadId].unreadMessages =
            payload.thread.unreadMessages;
        }
      }

      inboxAux.threads = threadsAux;
    }

    saveInbox(inboxAux);
  };

  /**
   * @function recieveMessageUpdate
   * @description This function is used to recieve a message update from websocket
   * @param {WebsocketMessage} payload - The message update payload
   * @returns {void}
   */
  const recieveMessageUpdate = (payload: WebsocketMessage) => {
    const inboxAux = { ...inbox };
    const activeThreadAux = inboxAux.activeThread;

    // Update message in active thread:
    // - If message belongs to active thread
    // - Is already in the messages array
    if (
      activeThreadAux?.id === payload.threadId &&
      activeThreadAux.messages.find((m) => m.id === payload.message.id)
    ) {
      activeThreadAux.messages = activeThreadAux.messages.map((m) =>
        m.id === payload.message.id ? payload.message : m,
      );

      inboxAux.activeThread = activeThreadAux;
    }

    const threadsAux = { ...inboxAux.threads };

    // Update thread in threads:
    // If thread is in threads
    if (threadsAux[payload.threadId]) {
      threadsAux[payload.threadId].endUser = payload.thread.endUser;
      threadsAux[payload.threadId].latestMessage = payload.thread.latestMessage;
      threadsAux[payload.threadId].updatedAt = payload.thread.updatedAt;
      threadsAux[payload.threadId].tags = payload.thread.tags;

      // If thread not activeThread, increment unreadMessages
      if (inboxAux.activeThread?.id !== payload.threadId) {
        threadsAux[payload.threadId].unreadMessages =
          payload.thread.unreadMessages;
      }

      inboxAux.threads = threadsAux;
    }

    saveInbox(inboxAux);
  };

  /**
   * @function recieveThreadUpdate
   * @description This function is used to recieve a thread update from websocket
   * @param {WebsocketMessage} payload - The thread update payload
   * @returns {void}
   */
  const recieveThreadUpdate = (payload: WebsocketMessage) => {
    const inboxAux = { ...inbox };

    // Thread if in threads
    if (inboxAux.threads[payload.id]) {
      const auxThread = inboxAux.threads[payload.id];
      // Update some properties
      auxThread.endUser = payload.thread.endUser;
      auxThread.latestMessage = payload.thread.latestMessage;
      auxThread.updatedAt = payload.thread.updatedAt;
      auxThread.tags = payload.thread.tags;
      auxThread.calls = payload.thread.calls;

      // If thread is activeThread, update it
      if (inboxAux.activeThread?.id === payload.id) {
        inboxAux.activeThread = auxThread;
      } else {
        // If thread is not activeThread, increment unreadMessages
        auxThread.unreadMessages = payload.thread.unreadMessages;
      }

      inboxAux.threads[payload.id] = auxThread;
    }
    saveInbox(inboxAux);
  };
  /**
   * @function markMessageRead
   * @description This function is used to mark a message as read
   * @param {number} id - The message id
   * @param {UUID} botId - The chatbot id
   * @returns {void}
   */
  const markMessageRead = async (id: number, botId: UUID) => {
    await postMessageRead(id, botId);
  };

  /**
   * @function assignThread
   * @description This function is used to assign a thread to a user
   * @param {number} threadId - The thread id
   * @param {str} userId - The user id
   * @returns {void}
   */
  const assignThread = async (threadId: number, userId: string) => {
    const response = await postThreadAssign(threadId, [userId]);

    if (response.status === 200) {
      const inboxAux = { ...inbox };
      const threadAux = inboxAux.threads[threadId];
      const relatedThreadsAux = Object.values(inboxAux.threads).filter(
        (t: Thread) =>
          t.conversationId === threadAux.conversationId && t.id !== threadId,
      );

      if (threadAux.assignees.includes(userId)) return;

      threadAux.assignees = [userId];
      inboxAux.threads[threadId] = threadAux;

      relatedThreadsAux.forEach((t: Thread) => {
        t.assignees = [userId];
        inboxAux.threads[t.id] = t;
      });

      if (inboxAux.activeThread?.id === threadAux.id) {
        inboxAux.activeThread = threadAux;
      }

      saveInbox(inboxAux);
    }
  };

  /**
   * @function exportThreadTranscriptFile
   * @description This function is used export threads into txt files
   * @param {string[]} threadIds - The thread ids
   * @returns {Object} { status } | { status, ...errors }
   */
  const exportThreadTranscriptFile = async (
    threadIds: string[],
  ): Promise<any> => {
    saveInbox({ ...inbox, isExportingThreads: true });
    const response = await getThreadTranscriptFile(threadIds);
    if (response.status !== 200) {
      toast.error('Error al procesar el archivo de transcripciones');
      return response;
    } else {
      toast.info('Procesando descarga de transcripciones...');
    }
    saveInbox({ ...inbox, isExportingThreads: false });
  };

  return {
    inbox,
    inboxFilters,
    inboxStatus,
    inboxPreferences,
    fetchingThreads,
    updateInboxStatus,
    filterToggleChannel,
    filterToggleChatbot,
    filterToggleTag,
    filterSetOrderBy,
    filterSetShowUnreplied,
    filterSetShowUnreadOnly,
    filterSetShowAssignedOnly,
    filterSetSearch,
    getThreads,
    openThread,
    getThreadMessages,
    setSelectedThreadIds,
    getMessageMediaBinary,
    getCallRecording,
    getS3File,
    uploadMessageMedia,
    assignThreadTag,
    unassignThreadTag,
    updateEndUserInfo,
    toggleDetailsExpanded,
    toggleThreadChatbot,
    sendMessage,
    sendTemplate,
    sendCall,
    recieveMessage,
    recieveMessageUpdate,
    recieveThreadUpdate,
    markMessageRead,
    assignThread,
    exportThreadTranscriptFile,
  };
};

export default useInbox;
