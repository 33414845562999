import React from 'react';

import './styles.css';

type ProgressBarProps = {
  progress?: number;
  color?: string;
  height?: number;
};

/**
 * ProgressBar
 * @description Component to display progress
 * @param {number} progress - The progress of the bar (0-100)
 * @param {string} color - The fill color of the bar
 * @returns {TSX.Element} ProgressBar component
 */

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  color = 'var(--primary)',
  height = 8,
}) => {
  return (
    <div className={'progress-bar'} style={{ height: `${height}px` }}>
      <div
        className={`progress-bar-fill ${
          progress === 100 ? 'progress-bar-fill-animated' : ''
        }`}
        style={{
          width: `${progress}%`,
          backgroundColor: color,
          height: `${height}px`,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
