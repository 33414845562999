import React from 'react';

import VoiceCard from '../voiceCard';

import { type VoicesData } from 'features/chatbots/providers/ChatbotProvider';

import './styles.css';

type VoiceSelectorProps = {
  voices: VoicesData;
  selectedVoiceId: string;
  setSelectedVoiceId: (voiceId: string) => void;
};

const VoiceSelector: React.FC<VoiceSelectorProps> = ({
  voices,
  selectedVoiceId,
  setSelectedVoiceId,
}) => {
  return (
    <div className={'voice-selector'}>
      {Object.values(voices).map((voice) => (
        <VoiceCard
          key={voice.id}
          voice={voice}
          isSelected={selectedVoiceId === voice.id}
          setSelectedVoiceId={setSelectedVoiceId}
        />
      ))}
    </div>
  );
};

export default VoiceSelector;
