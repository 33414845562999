export enum JobTypeEnum {
  DATA_EXPORT = 'data_export',
  DATA_IMPORT = 'data_import',
}

export enum JobStatusEnum {
  PENDING = 'pending',
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed',
}
